// index.js
import en from './en.json'
import th from './th.json'

export const defaultLocale = localStorage.getItem('locale') ?? 'th'

export const languages = {
  en: en,
  th: th
}
