import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const registerCreateUser = (username, password, prefix, firstname, lastname, birthday, phone, email, pasword, password_confirmation) =>
  client.post(apiConstant.REG_CREATE, {
    username, 
    password, 
    prefix, 
    firstname, 
    lastname, 
    birthday, 
    phone, 
    email, 
    pasword, 
    password_confirmation
  })
export const registerVerifyOtp = async (mobile, otp) => {
  try {
    return await client.post(apiConstant.REG_VERIFY_OTP, {
      mobile,
      otp
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const authenService = async (username, password) => {
  try {
    return await client.post(apiConstant.LOGIN, {
      username,
      password
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserProfileApiService = async () => {
  try {
    return await client.get(apiConstant.PROFILE)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateUserProfileService = async (payload) => {
  try {
    return await client.post(apiConstant.PROFIL_UPDATE, payload)
  } catch (error) {
    throw new Error(error)
  }
}

export const setUserProfileService = async (title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email) => {
  try {
    return await client.post(apiConstant.SET_USER_PROFILE, {
      title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const setUserAddressService = async ( data) => {
  try {
    return await client.post(apiConstant.SET_USER_ADDRESS, {
      data
    })
  } catch (error) {
    throw new Error(error)
  }
}
export const changePasswordService = async (data) => {
  try {
    return await client.post(apiConstant.SET_USER_CHANGE_PASS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const setNewPasswordService = async (user_id, new_password) => {
  try {
    return await client.post(apiConstant.SET_USER_CHANGE_PASS, {
      user_id, new_password
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getProvinceDistrictsSubdistrictsService = async (zipCode, language) => {
  try {
    return await client.post(apiConstant.GET_ADDR_BY_CODE, {
      zipCode, language
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const changeUserPasswordService = async (token, newPassword) => {
  try {
    return await client.post(apiConstant.CHANGE_USER_PASSWORD+token, {
      newPassword: newPassword
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const verifyResetPasswordEmailService = async (email) => {
  try {
    return await client.post(apiConstant.REQUEST_CHANGE_PASSWORD, {
      email
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const generateStaffRegisterService = async () => {
  try {
    return await client.get(apiConstant.USERS_GENERATE_STAFF_REGISTER)
  } catch (error) {
    throw new Error(error)
  }
}

export const registerStaffService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_REGISTER_STAFF+`/${data.id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}