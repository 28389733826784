// services/contractualActModule.js
import { ApiService } from '@/utils'
import { apiConstant2 } from '@/constants'

const client = new ApiService({})

export const getContractualActListService = async (params = {}) => {
    try {
        const {
            startDate,
            endDate,
            status,
            searchQuery,
            type
        } = params

        const queryParams = {
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
            ...(status && { status }),
            ...(type && { type }),
            ...(searchQuery && { searchQuery })
        }

        return await client.get(apiConstant2.CONTRACTUAL_ACT_GET_RECORDS, queryParams)
    } catch (error) {
        throw new Error(error)
    }
}

export const getContractualActByIdService = async (id) => {
    try {
        return await client.get(apiConstant2.CONTRACTUAL_ACT_BY_ID + `/${id}`)
    } catch (error) {
        throw new Error(error)
    }
}

export const addContractualActService = async (formData) => {
    try {
        return await client.post(apiConstant2.CONTRACTUAL_ACT_ADD, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const editContractualActService = async (formData) => {
    try {
        return await client.post(apiConstant2.CONTRACTUAL_ACT_EDIT, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const deleteContractualActService = async (id) => {
    try {
        return await client.delete(apiConstant2.CONTRACTUAL_ACT_DELETE + `/${id}`)
    } catch (error) {
        throw new Error(error)
    }
}

export const updateContractualActStatusService = async (id, status) => {
    try {
        return await client.post(apiConstant2.CONTRACTUAL_ACT_UPDATE_STATUS, {
            id,
            status
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const addContractualActFileService = async (formData) => {
    try {
        return await client.post(apiConstant2.CONTRACTUAL_ACT_ADD_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (error) {
        throw new Error(error)
    }
}

export const delContractualActFileService = async (formData) => {
    try {
        return await client.post(apiConstant2.CONTRACTUAL_ACT_DEL_FILE, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    } catch (error) {
        throw new Error(error)
    }
}
