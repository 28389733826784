import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const assignConsultService = async (formData) => {
  try {
    return await client.post(apiConstant.CONSULTATION_ASSIGN, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getStaffConsultService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_STAFF)
  } catch (error) {
    throw new Error(error)
  }
}