import { 
  SET_CONSULTATION_TYPE_LIST,
  SET_ADD_CONSULTATION_TYPE,
  SET_EDIT_CONSULTATION_TYPE,
  SET_DELETE_CONSULTATION_TYPE,
  SET_EDIT_CONSULTATION
} from '@/constants'

import {
  getAllConsultTopicService,
  addConsultTopicService,
  editConsultTopicService, 
  deleteConsultTopicService,
  editConsultService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const consultationTypeModule = {
  state: {
    consultationTypeList: []
  },

  getters: {
    consultationTypeList: state => state.consultationTypeList
  },

  mutations: {
    [SET_CONSULTATION_TYPE_LIST]: (state, payload) => {
      state.consultationTypeList = payload
    },
    [SET_ADD_CONSULTATION_TYPE]: (state, payload) => {
      state.consultationTypeList.push(payload)
    },
    [SET_EDIT_CONSULTATION_TYPE]: (state, payload) => {
      const index = state.consultationTypeList.findIndex(item => item.id === payload.id)
      if (index !== -1) {
        state.consultationTypeList[index] = payload
      }
    },
    [SET_DELETE_CONSULTATION_TYPE]: (state, id) => {
      state.consultationTypeList = state.consultationTypeList.filter(item => item.id !== id)
    },
    [SET_EDIT_CONSULTATION]: () => {},
  },

  actions: {
    getAllConsultationType: async ({ commit }) => {
      try {
        console.log('getAllConsultationType------')
        const resp = await getAllConsultTopicService()
        if (isSuccess(resp.status)) {
          commit(SET_CONSULTATION_TYPE_LIST, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error getting consultation types:', error)
        return false
      }
      return false
    },

    addConsultationType: async ({ commit }, { title }) => {
      try {
        const resp = await addConsultTopicService(title)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_CONSULTATION_TYPE, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error adding consultation type:', error)
        return false
      }
      return false
    },

    editConsultationType: async ({ commit }, { id, title }) => {
      try {
        const resp = await editConsultTopicService(id, title)
        if (isSuccess(resp.status)) {
          commit(SET_EDIT_CONSULTATION_TYPE, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error editing consultation type:', error)
        return false
      }
      return false
    },

    deleteConsultationType: async ({ commit }, { id }) => {
      try {
        const resp = await deleteConsultTopicService(id)
        if (isSuccess(resp.status)) {
          commit(SET_DELETE_CONSULTATION_TYPE, id)
          return true
        }
      } catch (error) {
        console.error('Error deleting consultation type:', error)
        return false
      }
      return false
    },

    editConsultApi: async ({ commit }, data) => {
      try {
        const resp = await editConsultService(data)
        if (isSuccess(resp.status)) {
          commit(SET_EDIT_CONSULTATION)
          return true
        }
      } catch (error) {
        console.error('Error deleting consultation type:', error)
        return false
      }
      return false
    }
  }
}

export { consultationTypeModule }