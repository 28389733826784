import {
  STATUS_SUCCESS,
  STATUS_OK,
  SET_EVALUATION_TEMPLATES,
  SET_EVALUATION_QUESTIONS,
  SET_EVALUATION_SCORES,
  SET_EVALUATION_DETAILS,
  SET_EVALUATION_LOADING,
  SET_CURRENT_TEMPLATE,
  SET_ERROR,
  DELETE_EVALUATION_QUESTIONS,
  DELETE_EVALUATION,
  ADD_EVALUATION,
  SET_EVALUATION_TYPE_LIST,
  SET_EVALUATION_ACTIVE,
  SET_EVALUATION_LISTSCORE
} from '@/constants'

import {
  addEvaluationScoreService,
  addEvaluationService,
  addEvaluationQuestionsService,
  addEvaluationQuestionsServiceV2,
  getQuestionsByTemplateIdService,
  listScoresByTemplateService,
  getEvaluationDetailsService,
  getEvaluationTemplateService,
  getEvaluationTemplateServiceV2,
  getListTemplateService,
  editEvaluationService,
  updateEvaluationStatusService,
  deleteEvaluationQuestionsService,
  deleteEvaluationService,
  addEvaluationTypeService,
  editEvaluationTypeService,
  getEvaluationTypeService,
  deleteEvaluationTypeService,
  setEvaluationScoreService,
  getEvaluationlistScores
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const evaluationModule = {
  state: {
    templates: [],           // List of all evaluation templates
    currentTemplate: null,   // Currently selected template
    questions: [],          // Questions for current template
    scores: [],            // Evaluation scores
    details: null,         // Detailed evaluation data
    loading: false,        // Loading state
    error: null,           // Error 
    typeList: [],
    listScores: []
  },

  getters: {
    evaluationTemplates: state => state.templates,
    currentTemplate: state => state.currentTemplate,
    evaluationQuestions: state => state.questions,
    evaluationScores: state => state.scores,
    evaluationDetails: state => state.details,
    listScores: state => state.listScores,
    isLoading: state => state.loading,
    hasError: state => !!state.error,
    errorMessage: state => state.error,
    typeList: state => state.typeList
  },

  mutations: {
    [SET_EVALUATION_TYPE_LIST]: (state, payload) => {
      state.typeList = payload
    },
    [SET_EVALUATION_TEMPLATES]: (state, payload) => {
      state.templates = payload
    },
    [SET_CURRENT_TEMPLATE]: (state, payload) => {
      state.currentTemplate = payload
    },
    [SET_EVALUATION_QUESTIONS]: (state, payload) => {
      state.questions = payload
    },
    [SET_EVALUATION_SCORES]: (state, payload) => {
      state.scores = payload
    },
    [SET_EVALUATION_DETAILS]: (state, payload) => {
      state.details = payload
    },
    [SET_EVALUATION_LISTSCORE]: (state, payload) => {
      return state.listScores = payload
    },

    [SET_EVALUATION_LOADING]: (state, payload) => {
      state.loading = payload
    },
    [SET_ERROR]: (state, payload) => {
      state.error = payload
    },
    [DELETE_EVALUATION_QUESTIONS]: () => { },
    [DELETE_EVALUATION]: () => { }
  },


  actions: {
    // Type Actions 
    getEvaluationTypesApi: async ({ commit }) => {
      try {
        const resp = await getEvaluationTypeService()
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_TYPE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting types:', error)
        return false
      }
    },

    addEvaluationTypeApi: async ({ commit }, data) => {
      try {
        const resp = await addEvaluationTypeService(data)
        commit(SET_EVALUATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding type:', error)
        return false
      }
    },

    editEvaluationTypeApi: async ({ commit }, data) => {
      try {
        const resp = await editEvaluationTypeService(data)
        commit(SET_EVALUATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing type:', error)
        return false
      }
    },

    deleteEvaluationTypeApi: async ({ commit }, id) => {
      try {
        const resp = await deleteEvaluationTypeService(id)
        commit(SET_EVALUATION_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting type:', error)
        return false
      }
    },
    setEvaluationScoreApi: async ({ commit }, { consultation_id, scores, template_id, gender,
      age,
      education_level,
      additional_comments,
      service_type,
      service_category,
    }) => {
      try {
        const resp = await setEvaluationScoreService(consultation_id, scores, template_id, gender,
          age,
          education_level,
          additional_comments,
          service_type,
          service_category,)

        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_EVALUATION_ACTIVE, resp.data)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    // Get list of all evaluation templates
    getListTemplate: async ({ commit }) => {
      try {
        commit(SET_EVALUATION_TEMPLATES, [])
        const resp = await getListTemplateService()

        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_TEMPLATES, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Submit evaluation score
    addEvaluationScore: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await addEvaluationScoreService(data)
        if (isSuccess(resp.status)) {
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Create new evaluation template
    addEvaluation: async ({ commit }, data) => {
      try {

        const resp = await addEvaluationService(data)
        if (isSuccess(resp.status)) {
          commit(ADD_EVALUATION, true)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(ADD_EVALUATION)
      }
    },

    // Add questions to template
    addEvaluationQuestionsV2: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await addEvaluationQuestionsServiceV2(data)
        if (isSuccess(resp.status)) {
          await commit(SET_EVALUATION_QUESTIONS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },
    addEvaluationQuestions: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await addEvaluationQuestionsService(data)
        if (isSuccess(resp.status)) {
          await commit(SET_EVALUATION_QUESTIONS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Get questions for specific template
    getQuestionsByTemplateId: async ({ commit }, id) => {
      try {
        const resp = await getQuestionsByTemplateIdService(id)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_QUESTIONS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      }
    },

    // List scores by template
    listScoresByTemplate: async ({ commit }, { id, params }) => {
      try {
        commit(SET_EVALUATION_SCORES, [])
        const resp = await listScoresByTemplateService(id, params)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_SCORES, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Get evaluation details
    getEvaluationDetails: async ({ commit }, { userId, templateId }) => {
      try {
        commit(SET_EVALUATION_DETAILS, [])
        const resp = await getEvaluationDetailsService(userId, templateId)
        if (isSuccess(resp.status)) {
          commit(SET_EVALUATION_DETAILS, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Edit evaluation template
    editEvaluation: async ({ commit, dispatch }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await editEvaluationService(data)
        if (isSuccess(resp.status)) {
          await dispatch('getListTemplate')  // Refresh template list
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Update evaluation status
    updateEvaluationStatus: async ({ commit }, data) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await updateEvaluationStatusService(data)
        return isSuccess(resp.status)
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Delete evaluation questions
    deleteEvaluationQuestions: async ({ commit }, questionId) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await deleteEvaluationQuestionsService(questionId)
        if (isSuccess(resp.status)) {
          commit(DELETE_EVALUATION_QUESTIONS)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Delete evaluation template
    deleteEvaluation: async ({ commit }, templateId) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await deleteEvaluationService(templateId)
        if (isSuccess(resp.status)) {
          commit(DELETE_EVALUATION)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    getEvaluationTemplateV2: async ({ commit }, id) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await getEvaluationTemplateServiceV2(id)
        if (isSuccess(resp.status)) {
          commit(SET_CURRENT_TEMPLATE, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },
    getEvaluationTemplate: async ({ commit }, id) => {
      try {
        commit(SET_EVALUATION_LOADING, true)
        const resp = await getEvaluationTemplateService(id)
        if (isSuccess(resp.status)) {
          commit(SET_CURRENT_TEMPLATE, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },
    getEvaluationlistScores: async ({ commit }, id) => {
      try {
        commit(SET_EVALUATION_LOADING, true)

        const resp = await getEvaluationlistScores(id)
        if (isSuccess(resp.status)) {

          commit(SET_EVALUATION_LISTSCORE, resp.data)
          return true
        }
        return false
      } catch (error) {
        commit(SET_ERROR, error.message)
        return false
      } finally {
        commit(SET_EVALUATION_LOADING, false)
      }
    },

    // Add convenience action to load template with questions
    getTemplateWithQuestions: async ({ dispatch }, id) => {
      try {
        const templateResult = await dispatch('getEvaluationTemplate', id)
        if (templateResult) {
          const questionsResult = await dispatch('getQuestionsByTemplateId', id)
          return questionsResult
        }
        return false
      } catch (error) {
        return false
      }
    },

    // Clear error state
    clearError: ({ commit }) => {
      commit(SET_ERROR, null)
    }
  }
}

export { evaluationModule }