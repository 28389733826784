import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getConsultByUserIdService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_BY_USER_ID)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAllConsultTopicService = async () => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_ALL_TOPICS)
  } catch (error) {
    throw new Error(error)
  }
}

export const addConsultationLogService = async (formData) => {
  try {
    return await client.post(apiConstant.CONSULTATION_ADD_LOG, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const edtConsultationLogService = async (data) => {
  try {
    return await client.post(apiConstant.CONSULTATION_EDIT_LOG, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const userRequestConsultationService = async (formData) => {
  try {
    return await client.post(apiConstant.CONSULTATION_USER_REQUEST, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserConsultByIdService = async (id) => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_USER_BY_ID+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}


export const getConsultByIdService = async (id) => {
  try {
    return await client.get(apiConstant.CONSULTATION_GET_BY_ID+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const getContactConsultByIdService = async (id) => {
  try {
    console.log('getContactConsultByIdService-----------------')
    return await client.get(apiConstant.CONSULTATION_GET_CONTACT_BY_ID+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAllConsultService = async (params = {}) => {
  try {
    const { 
      startDate,
      endDate,
      status,
      searchQuery,
      limit = 10,
      offset = 0
    } = params

    const queryParams = {
      ...(startDate && { startDate }),
      ...(endDate && { endDate }), 
      ...(status && { status }),
      ...(searchQuery && { searchQuery }),
      limit,
      offset
    }

    return await client.get(apiConstant.CONSULTATION_GET_ALL, queryParams)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteConsultService = async (id) => {
  try {
    return await client.delete(`${apiConstant.CONSULTATION_DELETE}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}