// knowledgeModule.js (Services)
import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getKnowledgeListService = async (params) => {
  try {
    return await client.get(apiConstant.KNOWLEDGE_GET_LAST,params)
  } catch (error) {
    throw new Error(error)
  }
}

export const getKnowledgeByIdService = async (id) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_BY_ID, {
      id
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const updateStatusKnowledgeService = async (id, status) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_UPDATE_STATUS, {
      id, status
    })
  } catch (error) {
    throw new Error(error)
  }
}
export const addKnowledgeService = async (formData) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editKnowledgeService = async (formData) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

// export const editKnowledgeService = async (document_number, subject, type, status, legal_topic, research_direction, consultation, description, documents, tag) => {
//   try {
//     return await client.post(apiConstant.KNOWLEDGE_EDIT, {
//       document_number, subject, type, status, legal_topic, research_direction, consultation, description, documents, tag
//     })
//   } catch (error) {
//     throw new Error(error)
//   }
// }

export const deleteKnowledgeService = async (id) => {
  try {
    return await client.delete(apiConstant.KNOWLEDGE_DELETE+`/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * Add document to existing Knowledge
 * @param {FormData} formData - Form data with new document
 * @returns {Promise} API Response
 */
export const addKnowledgeDocService = async (formData) => {
  try {
    return await client.post(apiConstant.KNOWLEDGE_ADD_DOC, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    throw new Error(error);
  }
};

/**
 * Delete document from Knowledge
 * @param {number} params.knowledge_id - Knowledge ID
 * @param {string} params.file_path - Path of file to delete
 * @returns {Promise} API Response
 */
export const deleteKnowledgeDocService = async (knowledge_id, file_path) => {
  try {
    return await client.delete(apiConstant.KNOWLEDGE_DELETE_DOC+`/${knowledge_id}?file_url=${file_path}`, {
      knowledge_id, file_path 
    });
  } catch (error) {
    throw new Error(error);
  }
};