import {
  SET_SUMMARY,
  SET_WORKSUMMARY,
  SET_getConsultData,
  SET_getCaseData,
  SET_getLegalCaseData,
  SET_getRegulationData,
  SET_getIntl_coopData,
  SET_getEvaluationScores

} from '@/constants'
import {
  getSummary,
  getConSult,
  getCaseData,
  getLegalCaseData,
  getRegulationData,
  getIntlCoopData,
  getEvaluationScores
} from '@/services'
import { isSuccess } from '@/utils/statusCheck'; // Status check utility

const dashboardModule = {
  state: {
    summary: [],
    worksummary: null,
    consultData: null,
    caseData: null,
    legalCaseData: null,
    regulationData: null,
    intlCoopData: null,
    evaluationData: null,
  },
  getters: {
    summary: (state) => state.summary,
    worksummary: (state) => state.worksummary,
    consultData: (state) => state.consultData,
    caseData: (state) => state.caseData,
    legalCaseData: (state) => state.legalCaseData,
    regulationData: (state) => state.regulationData,
    intlCoopData: (state) => state.intlCoopData,
    evaluationData: (state) => state.evaluationData,
  },
  mutations: {
    [SET_SUMMARY]: (state, payload) => {
      state.summary = payload
    },
    [SET_WORKSUMMARY]: (state, payload) => {
      state.worksummary = payload
    },
    [SET_getConsultData]: (state, payload) => {
      state.consultData = payload
    },
    [SET_getCaseData]: (state, payload) => {
      state.caseData = payload
    },
    [SET_getLegalCaseData]: (state, payload) => {
      state.legalCaseData = payload
    },
    [SET_getRegulationData]: (state, payload) => {
      state.regulationData = payload
    },
    [SET_getIntl_coopData]: (state, payload) => {
      state.intlCoopData = payload
    },
    [SET_getEvaluationScores]: (state, payload) => {
      state.evaluationData = payload
    },
  },
  actions: {
    getSummary: async ({ commit }, { period, year }) => {
      // alert('getSummary')
      try {
        const resp = await getSummary(period, year)
        if (isSuccess(resp.status)) {
          commit(SET_SUMMARY, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getConSult: async ({ commit }, year) => {
      // alert('getConSult'+year )
      try {
        const resp = await getConSult(year)
        if (isSuccess(resp.status)) {
          commit(SET_getConsultData, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getCaseData: async ({ commit }, year) => {
      // alert('getSummary')
      try {
        const resp = await getCaseData(year)
        if (isSuccess(resp.status)) {
          commit(SET_getCaseData, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getLegalCaseData: async ({ commit }, year) => {
      // alert('getSummary')
      try {
        const resp = await getLegalCaseData(year)
        if (isSuccess(resp.status)) {
          commit(SET_getLegalCaseData, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getRegulationData: async ({ commit }, year) => {
      // alert('getSummary')
      try {
        const resp = await getRegulationData(year)
        if (isSuccess(resp.status)) {
          commit(SET_getRegulationData, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getIntlCoopData: async ({ commit }, year) => {
      // alert('getSummary')
      try {
        const resp = await getIntlCoopData(year)
        if (isSuccess(resp.status)) {
          commit(SET_getIntl_coopData, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getEvaluationScores: async ({ commit }, year) => {
      // alert('getSummary')
      try {
        const resp = await getEvaluationScores(year)
        console.log(resp)
        if (isSuccess(resp.status)) {

          commit(SET_getEvaluationScores, resp.data)
        }
      } catch (error) {
        console.log('resp')
        commit(SET_getEvaluationScores, [])
        console.log(error)
      }
    },
  }
}

export {
  dashboardModule
}
