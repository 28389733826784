import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

// Main CRUD Services
export const getLegalCaseListService = async () => {
  try {
    return await client.get(apiConstant.LEGAL_CASE_GET_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const viewLegalCaseService = async (id) => {
  try {
    return await client.get(`${apiConstant.LEGAL_CASE_VIEW}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const insertLegalCaseService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_INSERT, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editLegalCaseService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.LEGAL_CASE_EDIT}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}

// Category Services
export const addLegalCaseCategoryService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_ADD_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editLegalCaseCategoryService = async (data) => {
  try {
    return await client.put(apiConstant.LEGAL_CASE_EDIT_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLegalCaseCategoryService = async () => {
  try {
    return await client.get(apiConstant.LEGAL_CASE_GET_CATEGORY)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLegalCaseCategoryService = async (id) => {
  try {
    return await client.delete(`${apiConstant.LEGAL_CASE_DELETE_CATEGORY}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// Type Services
export const addLegalCaseTypeService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_ADD_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editLegalCaseTypeService = async (data) => {
  try {
    return await client.put(apiConstant.LEGAL_CASE_EDIT_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLegalCaseTypeService = async () => {
  try {

    return await client.get(apiConstant.LEGAL_CASE_GET_TYPE)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLegalCaseTypeService = async (id) => {
  try {
    return await client.delete(`${apiConstant.LEGAL_CASE_DELETE_TYPE}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// File Services
export const insertLegalCaseFileService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_INSERT_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLegalCaseFileService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_DELETE_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

// Log Services
export const insertLegalCaseLogService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_INSERT_LOG, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editLegalCaseLogService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.LEGAL_CASE_EDIT_LOG}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const delLegalCaseLogService = async (id) => {
  try {
      return await client.delete(apiConstant.LEGAL_CASE_DEL_LOG + `/${id}`)
  } catch (error) {
      throw new Error(error)
  }
}
// Staff/Assignment Services
export const getLegalCaseStaffService = async () => {
  try {
    return await client.get(apiConstant.LEGAL_CASE_GET_STAFF)
  } catch (error) {
    throw new Error(error)
  }
}

export const assignLegalCaseService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLegalCaseAssignService = async (data) => {
  try {
    return await client.post(apiConstant.LEGAL_CASE_DELETE_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}