// Common/Global State
export const SET_EMAIL = 'setEmail'
export const SET_TOKEN = 'setToken'
export const SET_ISMEMBER = 'setIsMember'
export const CLEAR_SESSION = 'clearSession'
export const SET_ERRMESSAGE = 'setErrMessage'
export const SET_ACTIVEERROR = 'setActiveError'
export const SET_ACTIVEINFO = 'setActiveInfo'
export const SET_INFOMESSAGE = 'setInfoMessage'
export const SET_NAME = 'setName'
export const SET_LOADING = 'setLoading'
export const REQUEST_PROFILE = 'requestProfile'
export const SET_ACTIVE = 'setActive'
export const SET_PROFILE_SUPER_ADMIN = 'setPermissionSuperAdmin'

/* knowledge */
export const SET_KNOWLEDGELIST = 'setKnowledgeList'
export const SET_KNOWLEDGEL_BY_ID = 'setKnowledgeById'
export const SET_KNOWLEDGE_STATUS = 'SET_KNOWLEDGE_STATUS'
export const SET_KNOWLEDGE = 'SET_KNOWLEDGE'
export const SET_UPDATE_KNOWLEDGE = 'SET_UPDATE_KNOWLEDGE'
export const SET_ADD_KNOWLEDGE = 'SET_ADD_KNOWLEDGE'
export const KNOWLEDGE_UPDATE_STATUS = 'knowledge-update-status'
export const KNOWLEDGE_ADD = 'knowledge-add'
export const KNOWLEDGE_EDIT = 'knowledge-edit'
export const KNOWLEDGE_DELETE = 'knowledge-delete'
export const ADD_KNOWLEDGE_DOC = 'ADD_KNOWLEDGE_DOC'
export const DEL_KNOWLEDGE_DOC = 'DEL_KNOWLEDGE_DOC'

/* news */
export const SET_NEWSLIST = 'setNewsList'
export const SET_NEWS_BY_ID = 'setNewsById'
export const SET_NEWS_STATUS = 'setNewsStatus'
export const SET_ADD_NEWS = 'setAddNews'
export const SET_DEL_NEWS = 'setDelNews'
export const SET_UPDATE_NEWS = 'setUpdateNews'


/* FAQ */
export const SET_FAQLIST = 'setFaqList'
export const SET_FAQ = 'setFaq'
export const SET_FAQ_STATUS = 'setFaqStatus'
export const SET_UPDATE_FAQ = 'setUpdateFaq'
export const SET_ADD_FAQ = 'setAddFaq'

/* set register step */
export const SET_REGISTER_STEP = 'setRegisterStep'

/* Consultation */
export const GET_CONSULTATION = 'getConsultation'
export const SET_CONSULTATION = 'setConsultation'
export const SET_CONSULTATION_BY_ID = 'setConsultationById'
export const SET_CONSULTATION_LIST = 'setConsultationList'
export const SET_CONSULTATION_PROFILE_BUF = 'setConsultationProfileBuf'
export const SET_CONSULTATION_DETAIL_BUF = 'setConsultationDetailBuf'
export const SET_CONSULTATION_FILE_BUF = 'setConsultationFileBuf'
export const DEL_CONSULTATION = 'delConsultation'
export const SET_CONSULTATION_LOG = 'setConsultationLog'
export const EDIT_CONSULTATION_LOG = 'setConsultationLog'

/* Consultation Assign */
export const SET_CONSULTATION_ASSIGN = 'setConsultationAssign'
export const SET_STAFF_CONSULTATION = 'setStaffConsultation'

/* Consultation type */
export const SET_CONSULTATION_TYPE_LIST = 'setConsultationTypeList'
export const SET_ADD_CONSULTATION_TYPE = 'setAddConsultationType'
export const SET_EDIT_CONSULTATION_TYPE = 'setEditConsultationType'
export const SET_DELETE_CONSULTATION_TYPE = 'setDeleteConsultationType'
export const SET_EDIT_CONSULTATION = 'setEditConsultation'

/* Evaluation */
export const SET_EVALUATION_TEMPLATES = 'setEvaluationTemplates'
export const SET_CURRENT_TEMPLATE = 'setCurrentTemplate'
export const SET_EVALUATION_QUESTIONS = 'setEvaluationQuestions'
export const SET_EVALUATION_SCORES = 'setEvaluationScores'
export const SET_EVALUATION_DETAILS = 'setEvaluationDetails'
export const SET_EVALUATION_LOADING = 'setEvaluationLoading'
export const SET_ERROR = 'setError'
export const ADD_EVALUATION_SCORE = 'addEvaluationScore'
export const ADD_EVALUATION = 'addEvaluation'
export const ADD_EVALUATION_QUESTIONS = 'addEvaluationQuestions'
export const UPDATE_EVALUATION_STATUS = 'updateEvaluationStatus'
export const DELETE_EVALUATION = 'deleteEvaluation'
export const DELETE_EVALUATION_QUESTIONS = 'deleteEvaluationQuestions'

export const SET_EVALUATION_TYPE_LIST = 'setEvaluationTypeList'
export const SET_EVALUATION_ACTIVE = 'setEvaluationActive'
export const SET_EVALUATION_LISTSCORE = 'setEvaluationListScore'

/* user */
export const SET_USER_PROFILE = 'setUserProfile'
export const SET_USER_ADDRESS = 'setUserAddress'
export const SET_USER_LIST = 'setUserList'
export const SET_STAFF_DETAILS = 'setStaffDetails'
export const SET_USER_ROLES = 'setUserRoles'
export const SET_ADD_USER = 'setAddUser'
export const SET_UPDATE_USER = 'setUpdateUser'
export const SET_DELETE_USER = 'setDeleteUser'
export const SET_ADD_ROLE = 'setAddRole'
export const SET_DELETE_ROLE = 'setDeleteRole'
export const SET_UPDATE_ROLE = 'setUpdateRole'
export const SET_UPDATE_ROLE_PERMISSION = 'setUpdateRolePermission'
export const SET_LOCATION_DATA = 'setLocationData'

export const SET_LIST_PERMISSION = 'setListPermission'
export const SET_LIST_RULE = 'setListRule'

/* XAPI */
export const SET_XAPI_LIST = 'setXapiList'
export const SET_XAPI = 'setXapi'
export const SET_XAPI_LOGIN = 'setXapiLogin'
export const SET_XAPI_EDIT = 'setXapiEdit'
export const SET_XAPI_DELETE = 'setXapiDelete'


export const SET_INTL_COOP_ACTIVE = 'setIntlCoopActive'
/* International Cooperation Mutations */
export const SET_INTL_COOP_LIST = 'setIntlCoopList'
export const SET_INTL_COOP_DETAIL = 'setIntlCoopDetail'
export const SET_INTL_COOP_LOADING = 'setIntlCoopLoading'
export const SET_INTL_COOP_ERROR = 'setIntlCoopError'

// Category mutations
export const SET_INTL_COOP_CATEGORY_LIST = 'setIntlCoopCategoryList'
export const ADD_INTL_COOP_CATEGORY = 'addIntlCoopCategory'
export const UPDATE_INTL_COOP_CATEGORY = 'updateIntlCoopCategory'
export const DELETE_INTL_COOP_CATEGORY = 'deleteIntlCoopCategory'

// Type mutations
export const SET_INTL_COOP_TYPE_LIST = 'setIntlCoopTypeList'
export const ADD_INTL_COOP_TYPE = 'addIntlCoopType'
export const UPDATE_INTL_COOP_TYPE = 'updateIntlCoopType'
export const DELETE_INTL_COOP_TYPE = 'deleteIntlCoopType'

// File mutations
export const ADD_INTL_COOP_FILE = 'addIntlCoopFile'
export const DELETE_INTL_COOP_FILE = 'deleteIntlCoopFile'

// Log mutations
export const ADD_INTL_COOP_LOG = 'addIntlCoopLog'
export const UPDATE_INTL_COOP_LOG = 'updateIntlCoopLog'

// Staff/Assignment mutations
export const SET_INTL_COOP_STAFF_LIST = 'setIntlCoopStaffList'
export const SET_INTL_COOP_ASSIGNMENT = 'setIntlCoopAssignment'
export const DELETE_INTL_COOP_ASSIGNMENT = 'deleteIntlCoopAssignment'

// Status mutations  
export const UPDATE_INTL_COOP_STATUS = 'updateIntlCoopStatus'
export const UPDATE_INTL_COOP_PRIORITY = 'updateIntlCoopPriority'

// Content mutations
export const UPDATE_INTL_COOP_CONTENT = 'updateIntlCoopContent'
export const UPDATE_INTL_COOP_METADATA = 'updateIntlCoopMetadata'

// Attachment mutations
export const ADD_INTL_COOP_ATTACHMENT = 'addIntlCoopAttachment' 
export const DELETE_INTL_COOP_ATTACHMENT = 'deleteIntlCoopAttachment'

// Review mutations
export const ADD_INTL_COOP_REVIEW = 'addIntlCoopReview'
export const UPDATE_INTL_COOP_REVIEW = 'updateIntlCoopReview'

// Comment mutations
export const ADD_INTL_COOP_COMMENT = 'addIntlCoopComment'
export const UPDATE_INTL_COOP_COMMENT = 'updateIntlCoopComment'
export const DELETE_INTL_COOP_COMMENT = 'deleteIntlCoopComment'

/* Regulation State Mutations */
export const SET_REGULATION_LIST = 'setRegulationList'
export const SET_REGULATION_DETAIL = 'setRegulationDetail'
export const SET_REGULATION_CATEGORY_LIST = 'setRegulationCategoryList'
export const SET_REGULATION_TYPE_LIST = 'setRegulationTypeList'
export const SET_REGULATION_STAFF_LIST = 'setRegulationStaffList'
export const SET_REGULATION_ACTIVE = 'setRegulationActive'

/* Legal Case State Mutations */
export const SET_LEGAL_CASE_LIST = 'setLegalCaseList'
export const SET_LEGAL_CASE_DETAIL = 'setLegalCaseDetail'
export const SET_LEGAL_CASE_CATEGORY_LIST = 'setLegalCaseCategoryList'
export const SET_LEGAL_CASE_TYPE_LIST = 'setLegalCaseTypeList'
export const SET_LEGAL_CASE_STAFF_LIST = 'setLegalCaseStaffList'
export const SET_LEGAL_CASE_ACTIVE = 'setLegalCaseActive'

// Logging
export const SET_LOGGING_LIST = 'setLoggingList'
export const SET_LOGGING_DETAIL = 'setLoggingDetail'
export const SET_LOGGING_ERROR = 'setLoggingError'
export const SET_LOGGING_LOADING = 'setLoggingLoading'

/* staff register */
export const SET_STAFF_REGISTER_TOKEN = 'setStaffRegisterToken'
export const SET_STAFF_REGISTER_LINK = 'setStaffRegisterLink'

export const SET_SARABAN_LIST = 'setSarabanList'
export const SET_SARABAN_CREATE = 'setSarabanCreate'
export const SET_SARABAN_DETAIL = 'setSarabanDetail'
export const SET_SARABAN_LOADING = 'setSarabanLoading'
export const SET_SARABAN_ERROR = 'setSarabanError'

// Optional: Add status constants for consistent use across the application
export const API_STATUS = {
    OPEN: 'open',
    CLOSED: 'closed',
    PUBLISHED: 'published',
    DRAFT: 'draft'
  }
  
  // Optional: Add user types for consistent use
  export const USER_TYPES = {
    CUSTOMER: 1,
    STAFF: 2
  }
  
  // Optional: Add consultation status types
  export const CONSULTATION_STATUS = {
    PENDING: 'รอการพิจารณา',
    IN_REVIEW: 'นำเข้าพิจารณา',
    IN_PROGRESS: 'สืบเนื่อง',
    COMPLETED: 'ดำเนินการเสร็จสิ้น'
  }
  
  // Optional: Add common response messages
  export const RESPONSE_MESSAGES = {
    SUCCESS: 'ดำเนินการสำเร็จ',
    ERROR: 'เกิดข้อผิดพลาด',
    UNAUTHORIZED: 'ไม่มีสิทธิ์เข้าถึง',
    INVALID_DATA: 'ข้อมูลไม่ถูกต้อง'
  }
  
  // Optional: Add status constants for intl coop
  export const INTL_COOP_STATUS = {
    DRAFT: 0,
    ACTIVE: 1,
    COMPLETED: 2,
    CANCELLED: 3
  }
  
  // Optional: Add priority levels
  export const INTL_COOP_PRIORITY = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
    URGENT: 4
  }
  
// Optional status constants for regulation module
export const REGULATION_STATUS = {
    DRAFT: 'draft',
    PENDING: 'pending',
    PUBLISHED: 'published',
    ARCHIVED: 'archived'
  }
  
  // Optional priority levels for regulation assignments
  export const REGULATION_PRIORITY = {
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
    URGENT: 4
  }
  
  // Optional relationship types for staff assignments
  export const REGULATION_RELATIONSHIP = {
    PRIMARY: 1,
    SECONDARY: 2,
    REVIEWER: 3,
    OBSERVER: 4
  }
  
  // Optional regulation types
  export const REGULATION_TYPES = {
    POLICY: 'policy',
    PROCEDURE: 'procedure',
    GUIDELINE: 'guideline',
    STANDARD: 'standard'
  }
  
  // Optional document status types 
  export const REGULATION_DOCUMENT_STATUS = {
    DRAFT: 'draft',
    IN_REVIEW: 'in_review',
    APPROVED: 'approved',
    PUBLISHED: 'published',
    ARCHIVED: 'archived'
  }
  
  // Optional regulation visibility types
  export const REGULATION_VISIBILITY = {
    PUBLIC: 'public',
    PRIVATE: 'private',
    RESTRICTED: 'restricted'
  }
  
  // Optional regulation category types
  export const REGULATION_CATEGORY = {
    OPERATIONAL: 'operational',
    FINANCIAL: 'financial',
    COMPLIANCE: 'compliance',
    SECURITY: 'security',
    HR: 'human_resources',
    IT: 'information_technology'
  }

  export const PERMISSION_DATA = {
    VIEW: 1,
    ADD: 2,
    EDIT: 3,
    DELETE: 4,
    EXPORT: 5,
    ASSIGN: 6,
  }
  
