// intlCoopModule.js
import {
  SET_INTL_COOP_LIST,
  SET_INTL_COOP_DETAIL,
  SET_INTL_COOP_CATEGORY_LIST,
  SET_INTL_COOP_TYPE_LIST,
  SET_INTL_COOP_STAFF_LIST,
  SET_INTL_COOP_ACTIVE
} from '@/constants'

import {
  getIntlCoopListService,
  viewIntlCoopService,
  insertIntlCoopService,
  editIntlCoopService,
  addIntlCoopCategoryService,
  editIntlCoopCategoryService,
  getIntlCoopCategoryService,
  deleteIntlCoopCategoryService,
  addIntlCoopTypeService,
  editIntlCoopTypeService,
  getIntlCoopTypeService,
  deleteIntlCoopTypeService,
  insertIntlCoopFileService,
  deleteIntlCoopFileService,
  insertIntlCoopLogService,
  editIntlCoopLogService,
  getIntlCoopStaffService,
  assignIntlCoopService,
  deleteIntlCoopAssignService,
  delIntlCoopService,
delLogIntlCoopLogService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const intlCoopModule = {
  state: {
    intlCoopList: [],
    intlCoopDetail: null,
    categoryList: [],
    typeList: [],
    staffList: []
  },

  mutations: {
    [SET_INTL_COOP_LIST]: (state, payload) => {
      state.intlCoopList = payload
    },
    [SET_INTL_COOP_DETAIL]: (state, payload) => {
      state.intlCoopDetail = payload
    },
    [SET_INTL_COOP_CATEGORY_LIST]: (state, payload) => {
      state.categoryList = payload
    },
    [SET_INTL_COOP_TYPE_LIST]: (state, payload) => {
      state.typeList = payload
    },
    [SET_INTL_COOP_STAFF_LIST]: (state, payload) => {
      state.staffList = payload
    }
  },

  actions: {
    // Main CRUD Actions
    getIntlCoopList: async ({ commit }) => {
      try {
        const resp = await getIntlCoopListService()
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting international cooperation list:', error)
        return false
      }
    },

    viewIntlCoop: async ({ commit }, id) => {
      try {
        const resp = await viewIntlCoopService(id)
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_DETAIL, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error viewing international cooperation:', error)
        return false
      }
    },

    insertIntlCoop: async ({ commit }, data) => {
      try {
        const resp = await insertIntlCoopService(data)
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_ACTIVE)
          SET_INTL_COOP_ACTIVE
          return true
        }
        return false
      } catch (error) {
        console.error('Error inserting international cooperation:', error)
        return false
      }
    },

    editIntlCoop: async ({ commit }, { id, data }) => {
      try {
        const resp = await editIntlCoopService(id, data)
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error editing international cooperation:', error)
        return false
      }
    },

    // Category Actions
    getIntlCoopCategories: async ({ commit }) => {
      try {
        const resp = await getIntlCoopCategoryService()
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_CATEGORY_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting categories:', error)
        return false
      }
    },

    addIntlCoopCategory: async ({ commit }, data) => {
      try {
        const resp = await addIntlCoopCategoryService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding category:', error)
        return false
      }
    },

    editIntlCoopCategory: async ({ commit }, data) => {
      try {
        const resp = await editIntlCoopCategoryService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing category:', error)
        return false
      }
    },

    deleteIntlCoopCategory: async ({ commit }, id) => {
      try {
        const resp = await deleteIntlCoopCategoryService(id)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting category:', error)
        return false
      }
    },

    // Type Actions
    getIntlCoopTypesApi: async ({ commit }) => {
      try {
        const resp = await getIntlCoopTypeService()
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_TYPE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting types:', error)
        return false
      }
    },

    addIntlCoopTypeApi: async ({ commit }, data) => {
      try {
        // console.log('addType------------xx2222')
        const resp = await addIntlCoopTypeService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding type:', error)
        return false
      }
    },

    editIntlCoopTypeApi: async ({ commit }, data) => {
      try {
        const resp = await editIntlCoopTypeService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing type:', error)
        return false
      }
    },

    deleteIntlCoopTypeApi: async ({ commit }, id) => {
      try {
        const resp = await deleteIntlCoopTypeService(id)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting type:', error)
        return false
      }
    },

    // File Actions
    insertIntlCoopFile: async ({ commit }, data) => {
      try {
        const resp = await insertIntlCoopFileService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting file:', error)
        return false
      }
    },

    deleteIntlCoopFile: async ({ commit }, data) => {
      try {
        const resp = await deleteIntlCoopFileService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting file:', error)
        return false
      }
    },

    // Log Actions
    insertIntlCoopLog: async ({ commit }, data) => {
      try {
        const resp = await insertIntlCoopLogService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting log:', error)
        return false
      }
    },

    editIntlCoopLog: async ({ commit }, { id, data }) => {
      try {
        const resp = await editIntlCoopLogService(id, data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing log:', error)
        return false
      }
    },

    // Staff/Assignment Actions
    getIntlCoopStaff: async ({ commit }) => {
      try {
        const resp = await getIntlCoopStaffService()
        if (isSuccess(resp.status)) {
          commit(SET_INTL_COOP_STAFF_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting staff:', error)
        return false
      }
    },

    assignIntlCoop: async ({ commit }, data) => {
      try {
        const resp = await assignIntlCoopService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error assigning staff:', error)
        return false
      }
    },

    deleteIntlCoopAssign: async ({ commit }, data) => {
      try {
        const resp = await deleteIntlCoopAssignService(data)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting assignment:', error)
        return false
      }
    },
    deleteIntlCoop: async ({ commit },id) => {
      try {
        // alert(id)
        const resp = await delIntlCoopService(id)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting Regulation:', error)
        return false
      }
    },
    delIntlCoopLog: async ({ commit },id) => {
      try {
        // alert(id)
        const resp = await delLogIntlCoopLogService(id)
        commit(SET_INTL_COOP_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting Regulation:', error)
        return false
      }
    },
  }
}

export { intlCoopModule }