import { ApiService } from '@/utils'
import { apiConstant2 } from '@/constants'

const client = new ApiService({})

export const getSummary = async (period='month',year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_SUMMARY+'?period='+period+'&year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getConSult = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getConsultData+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getCaseData = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getCaseData+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLegalCaseData = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getLegalCaseData+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getRegulationData = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getRegulationData+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getIntlCoopData = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getIntl_coopData+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationScores = async (year='2024') => {
  try {
    return await client.get(apiConstant2.DASHBOARD_getEvaluationScores+'?year='+year)
  } catch (error) {
    throw new Error(error)
  }
}

