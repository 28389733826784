import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})


// Type Services
export const addEvaluationTypeService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editEvaluationTypeService = async (data) => {
  console.log(data)
  try {
    return await client.put(`${apiConstant.EVALUATION_EDIT_TYPE}/${data.id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationTypeService = async () => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_TYPE)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteEvaluationTypeService = async (id) => {
  try {
    return await client.delete(`${apiConstant.EVALUATION_DELETE_TYPE}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
export const addEvaluationScoreService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_SCORE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const addEvaluationService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const addEvaluationQuestionsServiceV2 = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_QUESTIONSV2, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const addEvaluationQuestionsService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_QUESTIONS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getQuestionsByTemplateIdService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_QUESTIONS.replace(':id', id))
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationlistScores = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_LIST_SCORES.replace(':id', id))
  } catch (error) {
    throw new Error(error)
  }
}
export const listScoresByTemplateService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_LIST_SCORES.replace(':id', id))
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationDetailsService = async (userId, templateId) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_DETAILS.replace(':user_id', userId).replace(':id', templateId))
  } catch (error) {
    throw new Error(error)
  }
}

export const getListTemplateService = async () => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const getEvaluationTemplateService = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_TEMPLATE + `/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
export const getEvaluationTemplateServiceV2 = async (id) => {
  try {
    return await client.get(apiConstant.EVALUATION_GET_TEMPLATEV2 + `/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const editEvaluationService = async (data) => {
  try {
    return await client.post(apiConstant.EVALUATION_EDIT, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateEvaluationStatusService = async (data) => {
  try {
    return await client.put(apiConstant.EVALUATION_UPDATE_STATUS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteEvaluationQuestionsService = async (questionId) => {
  try {
    return await client.delete(`${apiConstant.EVALUATION_DELETE_QUESTIONS}/${questionId}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteEvaluationService = async (templateId) => {
  try {
    return await client.delete(`${apiConstant.EVALUATION_DELETE}/${templateId}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const setEvaluationScoreService = async (consultation_id, scores, template_id, gender,
  age,
  education_level,
  additional_comments,
  service_type,
  service_category,) => {
  try {
    return await client.post(apiConstant.EVALUATION_ADD_CONSULT_SCORE, {
      consultation_id, scores, template_id, gender,
      age,
      education_level,
      additional_comments,
      service_type,
      service_category,
    })
  } catch (error) {
    throw new Error(error)
  }
}