import {
  SET_USER_PROFILE,
  SET_USER_ADDRESS,
  SET_USER_LIST,
  SET_STAFF_DETAILS,
  SET_USER_ROLES,
  SET_ADD_USER,
  SET_UPDATE_USER,
  SET_DELETE_USER,
  SET_ADD_ROLE,
  SET_DELETE_ROLE,
  SET_UPDATE_ROLE,
  SET_LOCATION_DATA,
  SET_LIST_PERMISSION,
  SET_LIST_RULE,
  SET_UPDATE_ROLE_PERMISSION
} from '@/constants'

import {
  registerUserService,
  getUserProfileService,
  setUserProfileService,
  setUserAddressService,
  changePasswordService,
  getAllUsersService,
  getStaffDetailsService,
  addStaffService,
  updateStaffService,
  deleteUserService,
  addRoleService,
  deleteRuleService,
  updateRuleService,
  updateRolePermissionsService,
  getProvinceDistrictsService,
  getListPermissionService,
  getListRuleService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const usersModule = {
  state: {
    userProfile: null,
    userAddress: null,
    userList: [],
    staffDetails: null,
    userRoles: [],
    locationData: null,
    permissionList: [],
    ruleList: []
  },

  getters: {
    userProfile: state => state.userProfile,
    userAddress: state => state.userAddress,
    userList: state => state.userList,
    staffDetails: state => state.staffDetails,
    userRoles: state => state.userRoles,
    locationData: state => state.locationData
  },

  mutations: {
    [SET_USER_PROFILE]: (state, payload) => {
      state.userProfile = payload
    },
    [SET_USER_ADDRESS]: (state, payload) => {
      state.userAddress = payload
    },
    [SET_USER_LIST]: (state, payload) => {
      state.userList = payload
    },
    [SET_STAFF_DETAILS]: (state, payload) => {
      state.staffDetails = payload
    },
    [SET_USER_ROLES]: (state, payload) => {
      state.userRoles = payload
    },
    [SET_LOCATION_DATA]: (state, payload) => {
      state.locationData = payload
    },
    [SET_ADD_USER]: () => {},
    [SET_UPDATE_USER]: () => {},
    [SET_DELETE_USER]: () => {},
    [SET_ADD_ROLE]: () => {},
    [SET_UPDATE_ROLE]: () => {},
    [SET_UPDATE_ROLE_PERMISSION]: () => {},
    [SET_LIST_PERMISSION]: (state, payload) => {
      state.permissionList = payload
    },
    [SET_LIST_RULE]: (state, payload) => {
      state.ruleList = payload
    },
  },

  actions: {
    registerUser: async ({ commit }, data) => {
      try {
        const resp = await registerUserService(data)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_USER)
          return true
        }
        return false
      } catch (error) {
        console.error('Error registering user:', error)
        return false
      }
    },

    getUserProfile: async ({ commit }) => {
      try {
        const resp = await getUserProfileService()
        if (isSuccess(resp.status)) {
          commit(SET_USER_PROFILE, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting user profile:', error)
        return false
      }
    },

    setUserProfile: async ({ commit }, data) => {
      try {
        const resp = await setUserProfileService(data)
        if (isSuccess(resp.status)) {
          commit(SET_USER_PROFILE, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error setting user profile:', error)
        return false
      }
    },

    setUserAddress: async ({ commit }, data) => {
      try {
        const resp = await setUserAddressService(data)
        if (isSuccess(resp.status)) {
          commit(SET_USER_ADDRESS, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error setting user address:', error)
        return false
      }
    },

    changePassword: async ({ commit }, data) => {
      try {
        const resp = await changePasswordService(data)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_USER)
          return true
        }
        return false
      } catch (error) {
        console.error('Error changing password:', error)
        return false
      }
    },

    getAllUsers: async ({ commit }, params) => {
      try {
        const resp = await getAllUsersService(params)
        if (isSuccess(resp.status)) {
          commit(SET_USER_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting all users:', error)
        return false
      }
    },

    getStaffDetails: async ({ commit }, userId) => {
      try {
        const resp = await getStaffDetailsService(userId)
        if (isSuccess(resp.status)) {
          commit(SET_STAFF_DETAILS, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting staff details:', error)
        return false
      }
    },

    addStaff: async ({ commit }, data) => {
      try {
        const resp = await addStaffService(data)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_USER)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding staff:', error)
        return false
      }
    },

    updateStaff: async ({ commit }, data) => {
      try {
        console.log('updateStaff-----------')
        console.log(data)
        const resp = await updateStaffService(data)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_USER)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating staff:', error)
        return false
      }
    },

    deleteUser: async ({ commit }, userId) => {
      try {
        const resp = await deleteUserService(userId)
        if (isSuccess(resp.status)) {
          commit(SET_DELETE_USER)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting user:', error)
        return false
      }
    },

    addRole: async ({ commit }, data) => {
      try {
        const resp = await addRoleService(data)
        if (isSuccess(resp.status)) {
          commit(SET_ADD_ROLE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error adding role:', error)
        return false
      }
    },

    deleteRole: async ({ commit }, userId) => {
      try {
        const resp = await deleteRuleService(userId)
        if (isSuccess(resp.status)) {
          commit(SET_DELETE_ROLE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting user:', error)
        return false
      }
    },

    updateRule: async ({ commit }, data) => {
      // "id": 1,
      // "role_name": "เจ้าหน้าที่จัดการงานข่าวสาร",
      // "description": "Role สำหรับผู้จัดการข่าวสาร"
      try {
        const resp = await updateRuleService(data)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_ROLE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating staff:', error)
        return false
      }
    },
    
    updateRolePermissions: async ({ commit }, data) => {
      try {
        const resp = await updateRolePermissionsService(data)
        if (isSuccess(resp.status)) {
          commit(SET_UPDATE_ROLE_PERMISSION)
          return true
        }
        return false
      } catch (error) {
        console.error('Error updating role permissions:', error)
        return false
      }
    },

    getProvinceDistricts: async ({ commit }, data) => {
      try {
        const resp = await getProvinceDistrictsService(data)
        if (isSuccess(resp.status)) {
          commit(SET_LOCATION_DATA, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting location data:', error)
        return false
      }
    },

    getListPermissionApi: async ({ commit }, params) => {
      try {
        commit(SET_LIST_PERMISSION, [])
        const resp = await getListPermissionService(params)
        if (isSuccess(resp.status)) {
          commit(SET_LIST_PERMISSION, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting all users:', error)
        return false
      }
    },

    getListRuleApi: async ({ commit }, params) => {
      try {
        commit(SET_LIST_RULE, [])
        const resp = await getListRuleService(params)
        if (isSuccess(resp.status)) {
          const encodedData = resp.data.map(item => {
            return {
              ...item,
              permission_data: typeof item.permission_data === 'string' 
                ? JSON.parse(item.permission_data)  // Already encoded
                : item.permission_data // Encode if object
            };
          });
          console.log(resp.data)
          console.log(encodedData)
          commit(SET_LIST_RULE, encodedData)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting all users:', error)
        return false
      }
    },

    

  }
}

export { usersModule }