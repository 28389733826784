import { 
  SET_NEWSLIST, 
  SET_NEWS_BY_ID, 
  SET_RELATED_NEWS, 
  SET_NEWS_STATUS,
  SET_ADD_NEWS,
  SET_UPDATE_NEWS,
  SET_DEL_NEWS
} from '@/constants'

import { 
  getNewsService,
  getNewsByIdService,
  getRelatedNewsService,
  updateNewsStatusService,
  addNewsService,
  editNewsService,
  deleteNewsService,
  deleteNewsImageService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const newsModule = {
  state: {
    newsById: {},
    newsList: [],
    relatedNews: []
  },

  getters: {
    newsList: (state) => state.newsList,
    newsById: (state) => state.newsById,
    relatedNews: (state) => state.relatedNews
  },

  mutations: {
    [SET_NEWSLIST]: (state, payload) => {
      state.newsList = payload
    },
    [SET_NEWS_BY_ID]: (state, payload) => {
      state.newsById = payload
    },
    [SET_RELATED_NEWS]: (state, payload) => {
      state.relatedNews = payload
    },
    [SET_NEWS_STATUS]: () => {
      // Handle status update if needed
    },
    [SET_ADD_NEWS]: () => {
      // Handle news addition if needed
    },
    [SET_UPDATE_NEWS]: () => {
      // Handle news update if needed
    },
    [SET_DEL_NEWS]: () => {
      // Handle news update if needed
    }
  },

  actions: {
    getNewsListApi: async ({ commit }, { keyword, startDate, endDate, sortBy, limit, offset }) => {
      try {
        const resp = await getNewsService(keyword, startDate, endDate, sortBy, limit, offset)
        if (isSuccess(resp.status)){
          commit(SET_NEWSLIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    getNewsApi: async ({ commit }, { id }) => {
      try {
        const resp = await getNewsByIdService(id)
        if (isSuccess(resp.status)){
          commit(SET_NEWS_BY_ID, resp.data)
          return true
        }else{
          return false
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },

    getRelatedNewsApi: async ({ commit }, { id, limit }) => {
      try {
        const resp = await getRelatedNewsService(id, limit)
        if (isSuccess(resp.status)){
          commit(SET_RELATED_NEWS, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    updateNewsStatusApi: async ({ commit }, { id, status }) => {
      try {
        const resp = await updateNewsStatusService(id, status)
        if (isSuccess(resp.status)){
          commit(SET_NEWS_STATUS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    addNewsApi: async ({ commit }, formData) => {
      try {
        const resp = await addNewsService(formData)
        if (isSuccess(resp.status)){
          commit(SET_ADD_NEWS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    editNewsApi: async ({ commit }, formData) => {
      try {
        const resp = await editNewsService(formData)
        if (isSuccess(resp.status)){
          commit(SET_UPDATE_NEWS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    deleteNewsApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteNewsService(id)
        if (isSuccess(resp.status)){
          commit(SET_DEL_NEWS)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    deleteNewsImageApi: async ({ news_id, file_name }) => {
      try {
        const resp = await deleteNewsImageService(news_id, file_name)
        if (isSuccess(resp.status)){
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    }
  }
}

export {
  newsModule
}
