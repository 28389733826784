import { localStorageService } from '@/utils'
import {
  SET_TOKEN,
  SET_ISMEMBER,
  CLEAR_SESSION,
  STATUS_SUCCESS,
  REQUEST_PROFILE,
  STATUS_OK,
  SET_USER_ADDRESS,
  SET_ACTIVE,
  SET_PROFILE_SUPER_ADMIN,
  SET_STAFF_REGISTER_TOKEN,
  SET_STAFF_REGISTER_LINK
} from '@/constants'
import { 
  authenService, 
  getUserProfileApiService, 
  setUserProfileService, 
  setUserAddressService, 
  setNewPasswordService, 
  getProvinceDistrictsSubdistrictsService, 
  changeUserPasswordService, 
  verifyResetPasswordEmailService,
  generateStaffRegisterService,
  registerStaffService,
  changePasswordService
} from '@/services'
const authModule = {
  state: {
    userAddress: {},
    isMember: localStorageService.isHasToken(),
    userProfile: null,
    // export const PERMISSION_DATA = {
    //   VIEW: 1,
    //   ADD: 2,
    //   EDIT: 3,
    //   DELETE: 4,
    //   EXPORT: 5,
    //   ASSIGN: 6,
    // }
    permission_data: null,
    permission_data_default: {
        "dashboard": [1, 2, 3, 4, 5, 6],
        "news": [1, 2, 3, 4, 5, 6],
        "consultations": [1, 2, 3, 4, 5, 6],
        "consultation_assign": [1, 2, 3, 4, 5, 6],
        "case": [1, 2, 3, 4, 5, 6],
        "legal_case": [1, 2, 3, 4, 5, 6],
        "regulation": [1, 2, 3, 4, 5, 6],
        "intl_coop": [1, 2, 3, 4, 5, 6],
        "evaluation": [1, 2, 3, 4, 5, 6],
        "knowledge": [1, 2, 3, 4, 5, 6],
        "faq": [1, 2, 3, 4, 5, 6],
        "xapi": [1, 2, 3, 4, 5, 6],
        "users": [1, 2, 3, 4, 5, 6],
        "staff":[1, 2, 3, 4, 5, 6],
        "logging": [1, 2, 3, 4, 5, 6]
    },
    staffRegisterToken: null,
    staffRegisterLink: null,
  },
  mutations: {
    [SET_TOKEN]: (_, payload) => {
      localStorageService.setToken(
        payload.payload,
        payload.username,
        payload.password
      )
    },
    [SET_ISMEMBER]: (state, payload) => {
      state.isMember = payload
      state.permission_data = {}
    },
    [CLEAR_SESSION]: () => {
      localStorageService.clearToken()
    },
    [REQUEST_PROFILE]: (state, payload) => {
      state.userProfile = payload[0] ? payload[0] : payload
    },
    [SET_USER_ADDRESS]: (state, payload) => {
      state.userAddress = payload
    },
    [SET_PROFILE_SUPER_ADMIN]: (state, payload) => {
      if(payload === 'super_admin'){
        state.permission_data = state.permission_data_default
      }else{
        state.permission_data = payload
      }
      localStorageService.setPermission(state.permission_data)
    },
    [SET_STAFF_REGISTER_TOKEN]: (state, payload) => {
      state.staffRegisterToken = payload
    },
    [SET_STAFF_REGISTER_LINK]: (state, payload) => {
      state.staffRegisterLink = payload 
    },
  },
  getters: {
    getIsMember: state => state.isMember,
    getPermissionActive: (state) => (key, value) => {
      return state.permission_data[key]?.includes(value);
    },
    getPermission: (state) => state.permission_data
  },
  actions: {
    getUserProfileApi: async ({ commit }) => {
      try {
        const resp = await getUserProfileApiService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          if(resp.data.user_type === 'super_admin'){
            commit(SET_PROFILE_SUPER_ADMIN, 'super_admin')
          }else{
            commit(SET_PROFILE_SUPER_ADMIN, resp.data.permission_data)
          }
          commit(REQUEST_PROFILE, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    loginAction: async ({ commit }, { username, password }) => {
      try {
        const resp = await authenService(username, password)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ISMEMBER, true) //TODO: for test
            commit(SET_TOKEN, { payload: resp, username, password }) //TODO: for test
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setUserProfileApi: async ({ commit }, { title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email}) => {
      try {
        const resp = await setUserProfileService(title_prefix, first_name, last_name, id_card_type, id_number,phone_number, mobile_number,email)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setUserAddressApi: async ( { commit }, data) => {
      try {
        const resp = await setUserAddressService(data)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    changePasswordApi: async ( { commit },data) => {
      try {
        const resp = await changePasswordService(data)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    setNewPasswordApi: async ( { commit },{ user_id, new_password}) => {
      try {
        const resp = await setNewPasswordService(user_id, new_password)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    getProvinceDistrictsSubdistrictsApi: async ({ commit }, { zipCode, language }) => {
      try {
        const resp = await getProvinceDistrictsSubdistrictsService(zipCode, language)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_USER_ADDRESS, resp.data)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },

    setToken: ({ commit }, payload) => {
      commit(SET_TOKEN, payload)
    },
    setIsMember: ({ commit }, payload) => {
      commit(SET_ISMEMBER, payload)
    },
    clearLogOut: ({ commit }) => {
      commit(SET_ISMEMBER, false)
      commit(CLEAR_SESSION)
    },
    changeUserPasswordApi: async ({ commit }, {token, newPassword}) => {
      try {
        const resp = await changeUserPasswordService(token, newPassword)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    verifyResetPasswordEmailApi: async ({ commit }, email) => {
      try {
        const resp = await verifyResetPasswordEmailService(email)
        if(resp){
          if (resp.status === STATUS_SUCCESS) {
            commit(SET_ACTIVE)
            return true
          } else {
            return false
          }
        }else{
          return false
        }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    generateStaffRegister: async ({ commit }) => {
      try {
        const resp = await generateStaffRegisterService()
        if (resp && (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK)) {
          commit(SET_STAFF_REGISTER_TOKEN, resp.data.token)
          commit(SET_STAFF_REGISTER_LINK, resp.data.registration_link)
          return true
        }
        return false
      } catch (err) {
        console.error('Error generating staff register:', err)
        return false
      }
    },
  
    registerStaff: async ({ commit }, data) => {
      try {
        const resp = await registerStaffService(data)
        commit(SET_ACTIVE)
        if (resp && (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK)) {
          return true
        }
        return false
      } catch (err) {
        console.error('Error registering staff:', err)
        return false 
      }
    }
  }
}

export { authModule }
