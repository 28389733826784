import {
  SET_XAPI_LIST,
  SET_XAPI,
  SET_XAPI_LOGIN,
  SET_XAPI_EDIT,
  SET_XAPI_DELETE
} from '@/constants'

import {
  createXapiService,
  getListXapiService,
  loginXapiService,
  editXapiService,
  deleteXapiService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const xapiModule = {
  state: {
    xapiList: []
  },

  getters: {
    xapiList: state => state.xapiList
  },

  mutations: {
    [SET_XAPI_LIST]: (state, payload) => {
      state.xapiList = payload
    },
    [SET_XAPI]: () => {},
    [SET_XAPI_LOGIN]: () => {}
  },

  actions: {
    createXapi: async ({ commit }, data) => {
      try {
        const resp = await createXapiService(data)
        if (isSuccess(resp.status)) {
          commit(SET_XAPI)
          return true
        }
        return false
      } catch (error) {
        console.error('Error creating xAPI:', error)
        return false
      }
    },

    getListXapi: async ({ commit }) => {
      try {
        console.log('getListXapi-----')
        commit(SET_XAPI_LIST, [])
        const resp = await getListXapiService()
        if (isSuccess(resp.status)) {
          commit(SET_XAPI_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting xAPI list:', error)
        return false
      }
    },
    
    editXapiApi: async ({ commit }, { x_api_id, name, description }) => {
      try {
        const resp = await editXapiService(x_api_id, name, description)
        if (isSuccess(resp.status)) {
          commit(SET_XAPI_EDIT)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },
    deleteXapiApi: async ({ commit }, { id }) => {
      try {
        const resp = await deleteXapiService(id)
        if (isSuccess(resp.status)) {
          commit(SET_XAPI_DELETE)
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
      return false
    },

    loginXapi: async ({ commit }, data) => {
      try {
        const resp = await loginXapiService(data)
        if (isSuccess(resp.status)) {
          commit(SET_XAPI_LOGIN, [])
          return resp.data
        }
        return false
      } catch (error) {
        console.error('Error logging in to xAPI:', error)
        return false
      }
    }
  }
}

export { xapiModule }