// sarabanModule.js
import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const createSarabanService = async (data) => {
  try {
    return await client.post(apiConstant.SARABAN_CREATE, {
      bucket_id: data.bucket_id,
      doc_header: data.doc_header,
      doc_detail: data.doc_detail,
      speed_id: data.speed_id || 1,
      secure_id: data.secure_id || 1, 
      type_id: data.type_id || 1,
      doc_add_by_user: data.doc_add_by_user,
      add_date: data.add_date,
      purpose_id: data.purpose_id || 1
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getSarabanByIdService = async (id) => {
  try {
    return await client.get(`${apiConstant.SARABAN_GET_BY_ID}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const getSarabanListService = async (params = {}) => {
  try {
    return await client.get(apiConstant.SARABAN_GET_LIST, { params })
  } catch (error) {
    throw new Error(error) 
  }
}