// intl-coop-module.js
import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getIntlCoopListService = async () => {
  try {
    return await client.get(apiConstant.INTL_COOP_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const viewIntlCoopService = async (id) => {
  try {
    return await client.get(`${apiConstant.INTL_COOP_VIEW}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const insertIntlCoopService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_INSERT, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editIntlCoopService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.INTL_COOP_EDIT}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const delIntlCoopService = async (id) => {
  try {
    return await client.delete(`${apiConstant.INTL_COOP_DEL}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
// Category Services
export const addIntlCoopCategoryService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_ADD_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editIntlCoopCategoryService = async (data) => {
  try {
    return await client.put(apiConstant.INTL_COOP_EDIT_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getIntlCoopCategoryService = async () => {
  try {
    return await client.get(apiConstant.INTL_COOP_GET_CATEGORY)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteIntlCoopCategoryService = async (id) => {
  try {
    return await client.delete(`${apiConstant.INTL_COOP_DELETE_CATEGORY}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// Type Services
export const addIntlCoopTypeService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_ADD_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editIntlCoopTypeService = async (data) => {
  try {
    return await client.put(apiConstant.INTL_COOP_EDIT_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getIntlCoopTypeService = async () => {
  try {
    return await client.get(apiConstant.INTL_COOP_GET_TYPE)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteIntlCoopTypeService = async (id) => {
  try {
    return await client.delete(`${apiConstant.INTL_COOP_DELETE_TYPE}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// File Services
export const insertIntlCoopFileService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_INSERT_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteIntlCoopFileService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_DELETE_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

// Log Services
export const insertIntlCoopLogService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_INSERT_LOG, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editIntlCoopLogService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.INTL_COOP_EDIT_LOG}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const delLogIntlCoopLogService = async (id) => {
  try {
    return await client.delete(`${apiConstant.INTL_COOP_DEL_LOG}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
// Staff/Assignment Services
export const getIntlCoopStaffService = async () => {
  try {
    return await client.get(apiConstant.INTL_COOP_GET_STAFF)
  } catch (error) {
    throw new Error(error)
  }
}

export const assignIntlCoopService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteIntlCoopAssignService = async (data) => {
  try {
    return await client.post(apiConstant.INTL_COOP_DELETE_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}