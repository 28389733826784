import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getLoggingByIdService = async (id) => {
  try {
    return await client.get(`${apiConstant.LOGGING_GET_BY_ID}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLoggingListService = async (params = {}) => {
  try {
    return await client.get(apiConstant.LOGGING_LIST, {
      params: {
        start_date: params.startDate,
        end_date: params.endDate,
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}