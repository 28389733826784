// statusCheck.js
import { STATUS_SUCCESS, STATUS_OK } from '@/constants'
// Define the statuses that are considered successful.
const SUCCESS_STATUSES = [STATUS_SUCCESS, STATUS_OK];

/**
 * Check if a given response status indicates success.
 * @param {object} response - The response object from the API.
 * @return {boolean} - True if the status is considered successful, false otherwise.
 */
function isSuccess(response) {
  return SUCCESS_STATUSES.includes(response);
}

export { isSuccess };
