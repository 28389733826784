// import { localStorageService } from '@/utils'
import {
  // SET_TOKEN,
  // SET_ISMEMBER,
  // CLEAR_SESSION,
  STATUS_SUCCESS,
  // REQUEST_PROFILE,
  STATUS_OK,
  SET_CONSULTATION,
  SET_CONSULTATION_LIST,
  SET_CONSULTATION_TYPE_LIST,
  SET_CONSULTATION_PROFILE_BUF,
  SET_CONSULTATION_DETAIL_BUF,
  SET_CONSULTATION_FILE_BUF,
  GET_CONSULTATION,
  DEL_CONSULTATION,
  SET_CONSULTATION_BY_ID,
  SET_CONSULTATION_LOG,
  EDIT_CONSULTATION_LOG,
  SET_CONTACT_CONSULTATION_BY_ID
} from '@/constants'
import { 
  getConsultByUserIdService, 
  getAllConsultTopicService, 
  userRequestConsultationService, 
  getUserConsultByIdService, 
  getAllConsultService, 
  deleteConsultService, 
  getConsultByIdService,
  addConsultationLogService ,
  edtConsultationLogService,
  getContactConsultByIdService
} from '@/services'
import { isSuccess } from '@/utils/statusCheck'

const consultationModule = {
  state: {
    consultationList: [],
    consultationTypeList: [],
    userConsultation: {
      profile: null,
      detail: null,
      files: null
    },
    consultationById: {},
    consultation: {},
    consultationContact: {}
  },
  mutations: {
    [SET_CONSULTATION_LIST]: (state, payload) => {
      state.consultationList = payload
    },
    [SET_CONSULTATION_TYPE_LIST]: (state, payload) => {
      state.consultationTypeList = payload
    },
    [SET_CONSULTATION_PROFILE_BUF]: (state, payload) => {
      state.userConsultation.profile = payload
    },
    [SET_CONSULTATION_DETAIL_BUF]: (state, payload) => {
      state.userConsultation.detail = payload
    },
    [SET_CONSULTATION_FILE_BUF]: (state, payload) => {
      state.userConsultation.files = payload
    },
    [GET_CONSULTATION]: (state, payload) => {
      state.consultation = payload
    },
    [SET_CONSULTATION]: (state, payload) => {
      state.consultation = payload
    },
    [SET_CONTACT_CONSULTATION_BY_ID]: (state, payload) => {
      state.consultationContact = payload
    },
    [SET_CONSULTATION_BY_ID]: (state, payload) => {
      state.consultationById = payload
    },
    [SET_CONSULTATION_LOG]: () => {},
    [EDIT_CONSULTATION_LOG]: () => {}
  },
  actions: {
    
    getConsultByIdApi: async ({ commit }, {id}) => {
      try {
        const resp = await getConsultByIdService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_BY_ID, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getContactConsultByIdApi: async ({ commit }, {id}) => {
      try {
        const resp = await getContactConsultByIdService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONTACT_CONSULTATION_BY_ID, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },

    getUserConsultById: async ({ commit }, {id}) => {
      try {
        const resp = await getUserConsultByIdService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(GET_CONSULTATION, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getConsultByUserId: async ({ commit }) => {
      try {
        const resp = await getConsultByUserIdService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_LIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    getAllConsultTopic: async ({ commit }) => {
      try {
        const resp = await getAllConsultTopicService()
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_TYPE_LIST, resp.data)
        }
      } catch (error) {
        console.log(error)
      }
    },
    userRequestConsultation: async ({ commit }, formData) => {
      try {
        const resp = await userRequestConsultationService(formData)
          if (isSuccess(resp.status)) {
            commit(SET_CONSULTATION)
            return true
          } else {
            return false
          }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },
    addConsultationLogApi: async ({ commit }, formData) => {
      try {
        const resp = await addConsultationLogService(formData)
          if (isSuccess(resp.status)) {
            commit(SET_CONSULTATION_LOG)
            return true
          } else {
            return false
          }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },

    editConsultationLogApi: async ({ commit }, formData) => {
      try {
        const resp = await edtConsultationLogService(formData)
          if (isSuccess(resp.status)) {
            commit(EDIT_CONSULTATION_LOG)
            return true
          } else {
            return false
          }
      } catch (err) {
        console.error(`im err : ${err}`)
        return false
      }
    },

    setUserConsultationProfile : ({ commit }, payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_PROFILE_BUF, payload)
    },
    setUserConsultationDetails : ({ commit },payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_DETAIL_BUF, payload)
    },
    setUserConsultationFiles : ({ commit },payload) => {
      console.log(payload)
      commit(SET_CONSULTATION_FILE_BUF, payload)
    },
    getAllConsult: async ({ commit }, params = {}) => {
      try {
        const resp = await getAllConsultService(params)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(SET_CONSULTATION_LIST, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error getting consultations:', error)
        return false
      }
      return false
    },
    deleteConsult: async ({ commit }, id) => {
      try {
        const resp = await deleteConsultService(id)
        if (resp.status === STATUS_SUCCESS || resp.status === STATUS_OK) {
          commit(DEL_CONSULTATION)
          return true
        }
      } catch (error) {
        console.error('Error deleting consultation:', error)
        return false
      }
      return false
    },
  }
}

export { consultationModule }
