import {
  SET_LEGAL_CASE_LIST,
  SET_LEGAL_CASE_DETAIL,
  SET_LEGAL_CASE_CATEGORY_LIST,
  SET_LEGAL_CASE_TYPE_LIST,
  SET_LEGAL_CASE_STAFF_LIST,
  SET_LEGAL_CASE_ACTIVE
} from '@/constants'

import {
  getLegalCaseListService,
  viewLegalCaseService,
  insertLegalCaseService,
  editLegalCaseService,
  addLegalCaseCategoryService,
  editLegalCaseCategoryService,
  getLegalCaseCategoryService,
  deleteLegalCaseCategoryService,
  addLegalCaseTypeService,
  editLegalCaseTypeService,
  getLegalCaseTypeService,
  deleteLegalCaseTypeService,
  insertLegalCaseFileService,
  deleteLegalCaseFileService,
  insertLegalCaseLogService,
  editLegalCaseLogService,
  delLegalCaseLogService,
  getLegalCaseStaffService,
  assignLegalCaseService,
  deleteLegalCaseAssignService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const legalCaseModule = {
  state: {
    legalCaseList: [],
    legalCaseDetail: null,
    categoryList: [],
    typeList: [],
    staffList: []
  },

  mutations: {
    [SET_LEGAL_CASE_LIST]: (state, payload) => {
      state.legalCaseList = payload
    },
    [SET_LEGAL_CASE_DETAIL]: (state, payload) => {
      state.legalCaseDetail = payload
    },
    [SET_LEGAL_CASE_CATEGORY_LIST]: (state, payload) => {
      state.categoryList = payload
    },
    [SET_LEGAL_CASE_TYPE_LIST]: (state, payload) => {
      state.typeList = payload
    },
    [SET_LEGAL_CASE_STAFF_LIST]: (state, payload) => {
      state.staffList = payload
    },
    [SET_LEGAL_CASE_ACTIVE]: () => {

    },
  },

  actions: {
    // Main CRUD Actions
    getLegalCaseList: async ({ commit }) => {
      try {
        const resp = await getLegalCaseListService()
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting legal case list:', error)
        return false
      }
    },

    viewLegalCase: async ({ commit }, id) => {
      try {
        // alert(id)
        const resp = await viewLegalCaseService(id)
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_DETAIL, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error viewing legal case:', error)
        return false
      }
    },

    insertLegalCase: async ({ commit }, data) => {
      try {
        const resp = await insertLegalCaseService(data)
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error inserting legal case:', error)
        return false
      }
    },

    editLegalCaseLegalCase: async ({ commit }, { id, data }) => {
      try {
        // console.log("======================================================")
        // console.log(data.id)
        const resp = await editLegalCaseService(id, data)
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error editing legal case:', error)
        return false
      }
    },

    // Category Actions
    getLegalCaseCategories: async ({ commit }) => {
      try {
        const resp = await getLegalCaseCategoryService()
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_CATEGORY_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting categories:', error)
        return false
      }
    },

    addLegalCaseCategory: async ({ commit }, data) => {
      try {
        const resp = await addLegalCaseCategoryService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding category:', error)
        return false
      }
    },

    editLegalCaseCategory: async ({ commit }, data) => {
      try {
        const resp = await editLegalCaseCategoryService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing category:', error)
        return false
      }
    },

    deleteLegalCaseCategory: async ({ commit }, id) => {
      try {
        const resp = await deleteLegalCaseCategoryService(id)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting category:', error)
        return false
      }
    },

    // Type Actions
    getLegalCaseTypes: async ({ commit }) => {

      try {
        const resp = await getLegalCaseTypeService()
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_TYPE_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting types:', error)
        return false
      }
    },

    addLegalCaseType: async ({ commit }, data) => {
      try {
        const resp = await addLegalCaseTypeService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error adding type:', error)
        return false
      }
    },

    editLegalCaseType: async ({ commit }, data) => {
      try {
        const resp = await editLegalCaseTypeService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing type:', error)
        return false
      }
    },

    deleteLegalCaseType: async ({ commit }, id) => {
      try {
        const resp = await deleteLegalCaseTypeService(id)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting type:', error)
        return false
      }
    },

    // File Actions  
    insertLegalCaseFile: async ({ commit }, data) => {
      try {
        // alert('insertLegalCaseFile') 
        const resp = await insertLegalCaseFileService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting file:', error)
        return false
      }
    },

    deleteLegalCaseFile: async ({ commit }, data) => {
      try {
        const resp = await deleteLegalCaseFileService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting file:', error)
        return false
      }
    },

    // Log Actions
    insertLegalCaseLog: async ({ commit }, data) => {
      try {
        const resp = await insertLegalCaseLogService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error inserting log:', error)
        return false
      }
    },

    editLegalCaseLog: async ({ commit }, { id, data }) => {
      try {
        const resp = await editLegalCaseLogService(id, data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error editing log:', error)
        return false
      }
    },

    delLegalCaseLog: async ({ commit },id ) => {
      try {
        const resp = await delLegalCaseLogService(id)
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_ACTIVE)
          return true
        }
        return false
      } catch (error) {
        console.error('Error deleting contractual act:', error)
        return false
      }
    },

    // Staff/Assignment Actions
    getLegalCaseStaff: async ({ commit }) => {
      try {
        const resp = await getLegalCaseStaffService()
        if (isSuccess(resp.status)) {
          commit(SET_LEGAL_CASE_STAFF_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting staff:', error)
        return false
      }
    },

    assignLegalCase: async ({ commit }, data) => {
      try {
        const resp = await assignLegalCaseService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error assigning staff:', error)
        return false
      }
    },

    deleteLegalCaseAssign: async ({ commit }, data) => {
      try {
        const resp = await deleteLegalCaseAssignService(data)
        commit(SET_LEGAL_CASE_ACTIVE)
        return isSuccess(resp.status)
      } catch (error) {
        console.error('Error deleting assignment:', error)
        return false
      }
    }
  }
}

export { legalCaseModule }