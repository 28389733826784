// litigationModule.js
import { ApiService } from '@/utils'
import { apiConstant2 } from '@/constants'

const client = new ApiService({})

export const getLitigationListService = async (params = {}) => {
  try {
    const {
      startDate,
      endDate,
      status,
      searchQuery
      // limit = 10,
      // offset = 0
    } = params

    const queryParams = {
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(status && { status }),
      ...(searchQuery && { searchQuery })
      // limit,
      // offset
    }

    return await client.get(apiConstant2.LITIGATION_GET_RECORDS, queryParams)
  } catch (error) {
    throw new Error(error)
  }
}


export const getLitigationSumListService = async (params = {}) => {
  try {
    const {
      startDate,
      endDate,
      status,
      searchQuery
      // limit = 10,
      // offset = 0
    } = params

    const queryParams = {
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(status && { status }),
      ...(searchQuery && { searchQuery })
      // limit,
      // offset
    }

    return await client.get(apiConstant2.LITIGATION_SUM_GET, queryParams)
  } catch (error) {
    throw new Error(error)
  }
}

export const getLitigationByIdService = async (id) => {
  try {
    return await client.get(apiConstant2.LITIGATION_BY_ID + `/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateStatusLitigationService = async (id, status) => {
  try {
    return await client.post(apiConstant2.LITIGATION_UPDATE_STATUS, {
      id, status
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const addLitigationServices = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editLitigationService = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLitigationService = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_DELETE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}
// getCaseTypeListService  
export const getCaseTypeListService = async () => {
  try {
    return await client.get(apiConstant2.LITIGATION_GET_TYPE)
  } catch (error) {
    throw new Error(error)
  }
}

export const addLitigationTypeServices = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_ADD_TYPE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editLitigationTypeService = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_EDIT_TYPE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteLitigationTypeService = async (id) => {
  try {
    return await client.delete(apiConstant2.LITIGATION_DELETE_TYPE + `/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const addLitigationFileServices = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_ADD_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const delLitigationFileServices = async (formData) => {
  try {
    console.log(formData);
    return await client.post(apiConstant2.LITIGATION_DEL_FILE, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}


export const addLitigationLogServices = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_LOG_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editLitigationLogService = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_LOG_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}


export const delLitigationLogService = async (formData) => {
  try {
    return await client.post(apiConstant2.LITIGATION_LOG_DEL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getLitigationStaffService = async () => {
  try {
    return await client.get(apiConstant2.LITIGATION_GET_STAFF)
  } catch (error) {
    throw new Error(error)
  }
}

export const assignCaseService = async (data) => {
  try {
    return await client.post(apiConstant2.LITIGATION_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteCaseAssignService = async (data) => {
  try {
    return await client.post(apiConstant2.LITIGATION_DELETE_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}