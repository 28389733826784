import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const registerUserService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_REGISTER, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getUserProfileService = async () => {
  try {
    return await client.get(apiConstant.USERS_GET_PROFILE)
  } catch (error) {
    throw new Error(error)
  }
}

export const setUserProfileService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_SET_PROFILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const setUserAddressService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_SET_ADDRESS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const changePasswordService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_CHANGE_PASSWORD, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getAllUsersService = async (params) => {
  try {
    return await client.get(apiConstant.USERS_GET_ALL, params)
  } catch (error) {
    throw new Error(error)
  }
}

export const getStaffDetailsService = async (userId) => {
  try {
    return await client.get(apiConstant.USERS_GET_STAFF_DETAILS, { user_id: userId })
  } catch (error) {
    throw new Error(error)
  }
}

export const addStaffService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_ADD_STAFF, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateStaffService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_UPDATE_STAFF, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteUserService = async (userId) => {
  try {
    return await client.post(apiConstant.USERS_DELETE, { user_id_to_delete: userId })
  } catch (error) {
    throw new Error(error)
  }
}

export const addRoleService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_ADD_ROLE, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const deleteRuleService = async (data) => {
  try {
    return await client.delete(apiConstant.USERS_DEL_ROLE+`/${data}`)
  } catch (error) {
    throw new Error(error)
  }
}
export const updateRuleService = async (data) => {
  try {
    return await client.put(apiConstant.USERS_UPDATE_ROLE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const updateRolePermissionsService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_UPDATE_ROLE_PERMISSIONS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getProvinceDistrictsService = async (data) => {
  try {
    return await client.post(apiConstant.USERS_GET_PROVINCE_DISTRICTS, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getListPermissionService = async () => {
  try {
    return await client.get(apiConstant.USERS_GET_LIST_PERMISSIONS)
  } catch (error) {
    throw new Error(error)
  }
}

export const getListRuleService = async () => {
  try {
    return await client.get(apiConstant.USERS_GET_LIST_RULE)
  } catch (error) {
    throw new Error(error)
  }
}

