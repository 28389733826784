import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

// Get News Listing with filters
export const getNewsService = async (keyword, startDate, endDate, sortBy, limit, offset) => {
  try {
    return await client.post(apiConstant.NEWS_GET, {
      keyword, startDate, endDate, sortBy, limit, offset
    })
  } catch (error) {
    throw new Error(error)
  }
}

// Get News by ID
export const getNewsByIdService = async (id) => {
  try {
    return await client.get(apiConstant.NEWS_GET_BY_ID + id)
  } catch (error) {
    throw new Error(error)
  }
}

// Get Related News
export const getRelatedNewsService = async (id, limit) => {
  try {
    return await client.get(apiConstant.NEWS_GET_RELATED + id, { limit })
  } catch (error) {
    throw new Error(error)
  }
}

// Update News Status
export const updateNewsStatusService = async (id, status) => {
  try {
    return await client.post(apiConstant.NEWS_UPDATE_STATUS, {
      id,
      status // 'published' or 'draft'
    })
  } catch (error) {
    throw new Error(error)
  }
}

// Add News
export const addNewsService = async (formData) => {
  try {
    return await client.post(apiConstant.NEWS_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

// Edit News
export const editNewsService = async (formData) => {
  try {
    return await client.post(apiConstant.NEWS_EDIT, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (error) {
    throw new Error(error)
  }
}

// Delete News
export const deleteNewsService = async (id) => {
  try {
    return await client.post(apiConstant.NEWS_DELETE, { id })
  } catch (error) {
    throw new Error(error)
  }
}

// Delete News Image
export const deleteNewsImageService = async (news_id, file_name) => {
  try {
    return await client.post(apiConstant.NEWS_DELETE_IMAGE, {
      news_id,
      file_name
    })
  } catch (error) {
    throw new Error(error)
  }
}
