import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap-icons/font/bootstrap-icons.css'

/* custom */
import '@/styles/custom.css'
import '@/styles/loading.css'

Vue.use(VueRouter)

// Move PERMISSION_DATA above the router configuration
const PERMISSION_DATA = {
  VIEW: 1,
  ADD: 2,
  EDIT: 3,
  DELETE: 4,
  EXPORT: 5,
  ASSIGN: 6,
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: '',
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      name: '/',
      redirect: '/',
      component: () => import('@/pages/Main_NoLayout.vue'),
      children: [
        {
          path: '/',
          name: '',
          meta: {
            public: true
          },
          component: () => import('@/pages/login/login.vue')
        },
        {
          path: 'login',
          name: 'login',
          meta: {
            public: true
          },
          component: () => import('@/pages/login/login.vue')
        },
        {
          path: '/register/:id',
          name: 'register',
          meta: {
            public: true
          },
          component: () => import('@/pages/register/register.vue')
        },
        {
          path: '/register_qrcode/:id',
          name: 'register-qrcode',
          meta: {
            public: true
          },
          component: () => import('@/pages/register/register_qrcode.vue')
        },
        {
          path: 'forgot-password', // New Path
          name: 'forgot-password',
          meta: {
            public: true
          },
          component: () => import('@/pages/login/forgetPassword.vue') // Replace with the actual component
        },
        {
          path: 'reset-password/:token', // New Path
          name: 'reset-password',
          meta: {
            public: true
          },
          component: () => import('@/pages/login/resetPassword.vue') // Replace with the actual component
        },
        {
          path: 'register-success', // New Path
          name: 'register-success',
          meta: {
            public: true
          },
          component: () => import('@/pages/register/registerSussess.vue') // Replace with the actual component
        }
      ]
    },
    {
      path: '/',
      name: 'home',
      redirect: '/',
      component: () => import('@/pages/Main.vue'),
      children: [
        {
          path: 'notFound',
          name: 'notFound',
          meta: {
            public: true
          },
          component: () => import('@/pages/login/notFound.vue')
        },
        // Dashboard
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            permission: 'dashboard',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/dashboard.vue')
        },
        // News routes
        {
          path: '/news',
          name: 'news',
          meta: {
            permission: 'news',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/news/newsList.vue')
        },
        {
          path: '/news/create',
          name: 'news-create',
          meta: {
            permission: 'news',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/news/newsCreate.vue')
        },
        {
          path: '/news/edit/:id',
          name: 'news-edit',
          meta: {
            permission: 'news',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/news/newsEdit.vue')
        },
        {
          path: '/news/:id',
          name: 'news_detail',
          meta: {
            permission: 'news',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/news/detail.vue')
        },
        // Consultation routes
        {
          path: '/consultation/list',
          name: '/consultation-list',
          meta: {
            permission: 'consultations',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationList.vue')
        },
        {
          path: '/consultation/type',
          name: 'consultation-type',
          meta: {
            permission: 'consultations',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationEditType.vue')
        },
        {
          path: '/consultation/assignment/list',
          name: 'consultation-assignment',
          meta: {
            permission: 'consultation_assign',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationListAssignment.vue')
        },
        {
          path: '/consultation/assignment/:id',
          name: 'consultation-assignment',
          meta: {
            permission: 'consultation_assign',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationAssignment.vue')
        },
        {
          path: '/consultation/edit/:id',
          name: 'consultation-edit',
          meta: {
            permission: 'consultations',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationEdit.vue')
        },
        {
          path: '/consultation/create',
          name: 'consultation-create',
          meta: {
            permission: 'consultations',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/consultation/consultationCreate.vue')
        },
        // Litigation routes
        {
          path: 'litigation/list',
          name: 'litigation',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/litigation/litigationList.vue')
        },
        {
          path: 'litigation/create',
          name: 'litigation-create',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/litigation/litigationCreate.vue')
        },
        {
          path: 'litigation/type',
          name: 'litigation-type',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/litigation/litigationType.vue')
        },
        {
          path: 'litigation/edit/:id',
          name: 'litigation-edit',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/litigation/litigationEdit.vue')
        },
        {
          path: 'litigation/detail/:id',
          name: 'litigation-detail',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/litigation/litigationPdf.vue')
        },
        {
          path: 'litigation/summary',
          name: 'litigation-summary',
          meta: {
            permission: 'case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/litigation/litigationSummary.vue')
        },
        // Legal Case routes
        {
          path: 'contractual-act/list',
          name: 'contractual-act-list',
          meta: {
            permission: 'legal_case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/contractualAct/contractualActList.vue')
        },
        {
          path: 'contractual-act/type',
          name: 'contractual-act-type',
          meta: {
            permission: 'legal_case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/contractualAct/contractualActType.vue')
        },
        {
          path: 'contractual-act/group',
          name: 'contractual-act-group',
          meta: {
            permission: 'legal_case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/contractualAct/contractualActGroup.vue')
        },
        {
          path: 'contractual-act/create',
          name: 'contractual-act-create',
          meta: {
            permission: 'legal_case',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/contractualAct/contractualActCreate.vue')
        },
        {
          path: 'contractual-act/edit/:id',
          name: 'contractual-act-edit',
          meta: {
            permission: 'legal_case',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/contractualAct/contractualActEdit.vue')
        },
        // Regulations routes
        {
          path: 'regulations/list',
          name: 'regulations-list',
          meta: {
            permission: 'regulation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/regulations/regulationsList.vue')
        },
        {
          path: 'regulations/type',
          name: 'regulations-type',
          meta: {
            permission: 'regulation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/regulations/regulationsType.vue')
        },
        {
          path: 'regulations/create',
          name: 'regulations-create',
          meta: {
            permission: 'regulation',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/regulations/regulationsCreate.vue')
        },
        {
          path: 'regulations/edit/:id',
          name: 'regulations-edit',
          meta: {
            permission: 'regulation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/regulations/regulationsEdit.vue')
        },
        // International Cooperation routes
        {
          path: 'international-cooperation/list',
          name: 'international-cooperation-list',
          meta: {
            permission: 'intl_coop',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/internationalCooperation/internationalCooperationList.vue')
        },
        {
          path: 'international-cooperation/type',
          name: 'international-cooperation-type',
          meta: {
            permission: 'intl_coop',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/internationalCooperation/internationalCooperationType.vue')
        },
        {
          path: 'international-cooperation/create',
          name: 'international-cooperation-create',
          meta: {
            permission: 'intl_coop',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/internationalCooperation/internationalCooperationCreate.vue')
        },
        {
          path: 'international-cooperation/edit/:id',
          name: 'international-cooperation-edit',
          meta: {
            permission: 'intl_coop',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/internationalCooperation/internationalCooperationEdit.vue')
        },
        // Evaluation routes
        {
          path: 'evaluation',
          name: 'evaluation',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/evaluation/evaluationList.vue')
        },
        // 
        {
          path: 'evaluation/template/:id/public',
          name: 'evaluation-add-template',
          meta: {
            permission: 'evaluation',
            public: true
          },
          component: () => import('@/pages/evaluation/evaluationIdTemplate.vue')
        },
        {
          path: 'evaluation/add/template',
          name: 'evaluation-add-template',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.ADD
          },
          component: () => import('@/pages/evaluation/evaluationAddTpl.vue')
        },
        {
          path: 'evaluation/type',
          name: 'evaluation-type',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/evaluation/evaluationType.vue')
        },
        {
          path: 'evaluation/edit/template/:id',
          name: 'evaluation-edit_template',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/evaluation/evaluationEditTplv2.vue')
        },
        {
          path: 'evaluation/score/:id',
          name: 'evaluation-score',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/evaluation/evaluationScore.vue')
        },
        {
          path: 'evaluation/score/detail/:user_id/:id',
          name: 'evaluation-score-detail',
          meta: {
            permission: 'evaluation',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/evaluation/evaluationScoreDetail.vue')
        },
        // Knowledge Base routes
        {
          path: 'knowledge-base',
          name: 'knowledge-base',
          meta: {
            permission: 'knowledge',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/knowledgeBase/knowledgeBaseList.vue')
        },
        {
          path: 'knowledge-base/create',
          name: 'knowledge-base-create',
          meta: {
            permission: 'knowledge',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/knowledgeBase/knowledgeBaseCreate.vue')
        },
        {
          path: 'knowledge-base/edit/:id',
          name: 'knowledge-base-edit',
          meta: {
            permission: 'knowledge',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/knowledgeBase/knowledgeBaseEdit.vue')
        },
        {
          path: 'knowledge-base/review/:id',
          name: 'knowledge-base-review',
          meta: {
            permission: 'knowledge'
          },
          component: () => import('@/pages/knowledgeBase/knowledgeBaseReview.vue')
        },
        // FAQ routes
        {
          path: 'faq',
          name: 'faq',
          meta: {
            permission: 'faq',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/faq/faqList.vue')
        },
        {
          path: 'faq/create',
          name: 'faq/create',
          meta: {
            permission: 'faq',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/faq/faqCreate.vue')
        },
        {
          path: 'faq/edit/:id',
          name: 'faq-edit',
          meta: {
            permission: 'faq',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/faq/faqEdit.vue')
        },
        // XAPI routes
        {
          path: 'xapi',
          name: 'xapi',
          meta: {
            permission: 'xapi',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/xapi/xapiList.vue')
        },
        {
          path: 'xapi/history/:id',
          name: 'xapi-history',
          meta: {
            permission: 'xapi',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/xapi/xapiHistory.vue')
        },
        // User routes
        {
          path: 'user',
          name: 'user',
          meta: {
            permission: 'users',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/user/userList.vue')
        },
        {
          path: 'user/create',
          name: 'user-create',
          meta: {
            permission: 'users',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/user/userCreate.vue')
        },
        {
          path: 'user/edit/:id',
          name: 'user-edit',
          meta: {
            permission: 'users',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/user/userEdit.vue')
        },
        // Staff/Member routes
        {
          path: 'member/list',
          name: 'member-list',
          meta: {
            permission: 'staff',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/member/memberList.vue')
        },
        {
          path: 'member/permission/group',
          name: 'member-permission-group',
          meta: {
            permission: 'staff',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/member/permissionsGroup.vue')
        },
        {
          path: 'member/create',
          name: 'member-create',
          meta: {
            permission: 'staff',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/member/memberCreate.vue')
        },
        {
          path: 'member/edit/:id',
          name: 'member-edit',
          meta: {
            permission: 'staff',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/member/memberEdit.vue')
        },
        // Logging routes
        {
          path: 'log',
          name: 'log',
          meta: {
            permission: 'logging',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/log/logList.vue')
        },
        // Profile route
        {
          path: 'profile',
          name: 'profile',
          meta: {
            public: true
          },
          component: () => import('@/pages/profile/profile.vue')
        },
        // Consultation detail route
        {
          path: 'consultation/:id',
          name: 'member/detail',
          meta: {
            permission: 'consultations',
            permission_id: PERMISSION_DATA.VIEW
          },
          component: () => import('@/pages/consultation/consultationDetail.vue')
        }
      ]
    }
  ]
}

router.beforeEach(async (to, from, next) => {
  try {
    const isAuthenticated = store.state.authModule.isMember;
    if (isAuthenticated) {
      if(to.name === 'login' || to.name === ''){
        next({ name: 'dashboard' });
      }
    }
    // Check if route is public
    const isPublicRoute = to.matched.some(record => record.meta.public);
    if (isPublicRoute) {
      next();
      return;
    }

    // Check if user is authenticated

    if (!isAuthenticated && !isPublicRoute) {
      next('/login');
      return;
    }

    // Find required permissions for this route
    const routeWithPermission = to.matched.find(record => record.meta.permission);
    const requiredPermission = routeWithPermission?.meta.permission;
    const requiredPermissionId = routeWithPermission?.meta.permission_id;

    // If no permission is specified and route is not public, deny access
    if (!requiredPermission) {
      console.warn(`Access denied to ${to.path} - No permission specified`);
      next('/notFound');
      return;
    }

    // Fetch user permissions if not already loaded
    if (!store.getters.getPermission) {
      await store.dispatch('getUserProfileApi');
    }

    // Check if user has required permission
    const hasViewPermission = store.getters.getPermissionActive(
      requiredPermission,
      requiredPermissionId
    );

    if (hasViewPermission) {
      next();
    } else {
      // If access is denied, try to find an accessible page
      const accessibleRoute = await findAccessiblePage(router.options.routes);
      
      if (accessibleRoute) {
        console.warn(
          `Access denied to ${to.path} - Redirecting to accessible page: ${accessibleRoute.path}`
        );
        next(accessibleRoute.path);
      } else {
        console.warn(
          `Access denied to ${to.path} - No accessible pages found`
        );
        next('/notFound');
      }
    }
  } catch (error) {
    console.error('Router guard error:', error);
    next('/error');
  }
});

// Helper function to find the first accessible page
async function findAccessiblePage(routes) {
  const checkRoute = async (route) => {
    // Skip routes without meta permissions
    if (!route.meta?.permission) {
      return null;
    }

    // Check if user has permission for this route
    const hasPermission = store.getters.getPermissionActive(
      route.meta.permission,
      route.meta.permission_id
    );

    if (hasPermission) {
      return route;
    }
    return null;
  };

  // Recursively check routes and their children
  const checkRoutes = async (routes) => {
    for (const route of routes) {
      // Check current route
      const accessibleRoute = await checkRoute(route);
      if (accessibleRoute) {
        return accessibleRoute;
      }

      // Check children routes if they exist
      if (route.children && route.children.length > 0) {
        const accessibleChildRoute = await checkRoutes(route.children);
        if (accessibleChildRoute) {
          return accessibleChildRoute;
        }
      }
    }
    return null;
  };

  // Start checking from top-level routes
  return await checkRoutes(routes);
}

// Router guard implementation
// router.beforeEach((to, from, next) => {
//   // Check if route is public
//   const isPublicRoute = to.matched.some(record => record.meta.public)
  
//   if (isPublicRoute) {
//     next()
//     return
//   }

//   // Check if user is authenticated (you may want to add this check)
//   const isAuthenticated = store.state.authModule.isMember
//   if (!isAuthenticated) {
//     next('/login')
//     return
//   }

//   // Find the permission required for this route
//   const requiredPermission = to.matched.find(record => record.meta.permission)?.meta.permission
//   const requiredPermissionId = to.matched.find(record => record.meta.permission_id)?.meta.permission_id

//   // If no permission is specified and route is not public, deny access
//   if (!requiredPermission) {
//     next('/notFound')
//     return
//   }
//   if(!store.getters.getPermission){
//     const resp =  await store.dispatch('getUserProfileApi');
//   }
//   // Check if user has view permission (1) for this route
//   const hasViewPermission = store.getters.getPermissionActive(requiredPermission, requiredPermissionId)
//   console.log('Router guard implementation----------')
//   console.log(hasViewPermission, requiredPermission, requiredPermissionId, store.getters.getPermission)
//   console.log(store.getters.getPermission)
//   if (hasViewPermission) {
//     next()
//   } else {
//     // Log denied access attempt (optional)
//     console.warn(`Access denied to ${to.path} - Missing ${requiredPermission} permission`)
//     next('/notFound')
//   }
// })

// Handle navigation errors
router.onError((error) => {
  console.error('Navigation error:', error)
  router.push('/notFound')
})

export default router