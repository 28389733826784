// saraban-module.js
import { 
  SET_SARABAN_LIST,
  SET_SARABAN_DETAIL,
  SET_SARABAN_LOADING,
  SET_SARABAN_ERROR,
  SET_SARABAN_CREATE,
  STATUS_SUCCESS
} from '@/constants'

import {
  createSarabanService,
  getSarabanByIdService, 
  getSarabanListService
} from '@/services'

const sarabanModule = {
  state: {
    sarabanList: [],
    sarabanCreate: {},
    sarabanDetail: {},
    isLoading: false,
    error: null
  },

  mutations: {
    [SET_SARABAN_LIST]: (state, payload) => {
      state.sarabanList = payload
    },
    [SET_SARABAN_DETAIL]: (state, payload) => {
      state.sarabanDetail = payload  
    },
    [SET_SARABAN_CREATE]: (state, payload) => {
      state.sarabanCreate = payload  
    },
    [SET_SARABAN_LOADING]: (state, payload) => {
      state.isLoading = payload
    },
    [SET_SARABAN_ERROR]: (state, payload) => {
      state.error = payload
    }
  },

  actions: {
    createSaraban: async ({ commit }, data) => {
      try {
        commit(SET_SARABAN_LOADING, true)
        const response = await createSarabanService(data)
        commit(SET_SARABAN_CREATE, response.data.data)
        if (response.status === STATUS_SUCCESS) {
          return true
        }
        return false
      } catch (error) {
        console.error('Error creating saraban:', error)  
        commit(SET_SARABAN_ERROR, error.message)
        return false
      } finally {
        commit(SET_SARABAN_LOADING, false)
      }
    },

    getSarabanById: async ({ commit }, id) => {
      try {
        commit(SET_SARABAN_LOADING, true)
        const response = await getSarabanByIdService(id)
        if (response.status === STATUS_SUCCESS) {
          commit(SET_SARABAN_DETAIL, response.data.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting saraban details:', error)
        commit(SET_SARABAN_ERROR, error.message)
        return false
      } finally {
        commit(SET_SARABAN_LOADING, false)
      }
    },

    getSarabanList: async ({ commit }, params = {}) => {
      try {
        commit(SET_SARABAN_LOADING, true)
        const response = await getSarabanListService(params)
        if (response.status === STATUS_SUCCESS) {
          commit(SET_SARABAN_LIST, response.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting saraban list:', error)
        commit(SET_SARABAN_ERROR, error.message)
        return false
      } finally {
        commit(SET_SARABAN_LOADING, false)  
      }
    }
  }  
}

export { sarabanModule }