import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getListService = async () => {
  try {
    return await client.get(apiConstant.REGULATION_GET_LIST)
  } catch (error) {
    throw new Error(error)
  }
}

export const viewService = async (id) => {
  try {
    return await client.get(`${apiConstant.REGULATION_VIEW}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

export const insertService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_INSERT, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.REGULATION_EDIT}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const delService = async (id) => {
  try {
    return await client.delete(`${apiConstant.REGULATION_DEL}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
// Category Services
export const addCategoryService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_ADD_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editCategoryService = async (data) => {
  try {
    return await client.put(apiConstant.REGULATION_EDIT_CATEGORY, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getCategoryService = async () => {
  try {
    return await client.get(apiConstant.REGULATION_GET_CATEGORY)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteCategoryService = async (id) => {
  try {
    return await client.delete(`${apiConstant.REGULATION_DELETE_CATEGORY}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// Type Services
export const addRegulationTypeService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_ADD_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editRegulationTypeService = async (data) => {
  try {
    return await client.put(apiConstant.REGULATION_EDIT_TYPE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const getRegulationTypeService = async () => {
  try {
    return await client.get(apiConstant.REGULATION_GET_TYPE)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteRegulationTypeService = async (id) => {
  try {
    return await client.delete(`${apiConstant.REGULATION_DELETE_TYPE}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// File Services
export const insertFileService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_INSERT_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteFileService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_DELETE_FILE, data)
  } catch (error) {
    throw new Error(error)
  }
}

// Log Services
export const insertLogService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_INSERT_LOG, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const editLogService = async (id, data) => {
  try {
    return await client.put(`${apiConstant.REGULATION_EDIT_LOG}/${id}`, data)
  } catch (error) {
    throw new Error(error)
  }
}
export const delLogService = async (id) => {
  try {
    return await client.delete(`${apiConstant.REGULATION_DEL_LOG}/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}

// Staff/Assignment Services
export const getStaffService = async () => {
  try {
    return await client.get(apiConstant.REGULATION_GET_STAFF)
  } catch (error) {
    throw new Error(error)
  }
}

export const assignService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteAssignService = async (data) => {
  try {
    return await client.post(apiConstant.REGULATION_DELETE_ASSIGN, data)
  } catch (error) {
    throw new Error(error)
  }
}