import { SET_CONSULTATION_ASSIGN, SET_STAFF_CONSULTATION } from '@/constants'
import { assignConsultService, getStaffConsultService } from '@/services'
import { isSuccess } from '@/utils/statusCheck'

const consultationAssignModule = {
  state: {
    staffConsultations: [],
    assignedConsultations: []
  },

  getters: {
    staffConsultations: state => state.staffConsultations,
    assignedConsultations: state => state.assignedConsultations
  },

  mutations: {
    [SET_CONSULTATION_ASSIGN]: (state, payload) => {
      state.assignedConsultations.push(payload)
    },
    [SET_STAFF_CONSULTATION]: (state, payload) => {
      state.staffConsultations = payload
    }
  },

  actions: {
    assignConsultApi: async ({ commit }, formData) => {
      try {
        const resp = await assignConsultService(formData)
        if (isSuccess(resp.status)) {
          commit(SET_CONSULTATION_ASSIGN, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error assigning consultation:', error)
        return false
      }
      return false
    },

    getStaffConsultApi: async ({ commit }) => {
      try {
        const resp = await getStaffConsultService()
        if (isSuccess(resp.status)) {
          commit(SET_STAFF_CONSULTATION, resp.data)
          return true
        }
      } catch (error) {
        console.error('Error getting staff consultations:', error)
        return false
      }
      return false
    }
  }
}

export { consultationAssignModule }