import Vue from 'vue'
import App from './App.vue'
import router from '@/routes'


import store from '@/store'
import VueI18n from 'vue-i18n'
import moment from 'moment'
import lodash from 'lodash'
import VTooltip from 'v-tooltip'
import VueCookies from 'vue-cookies'
import Vuelidate from 'vuelidate'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import Bootstrap and BootstrapVue CSS files
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'quill/dist/quill.snow.css'

import {
    languages,
    defaultLocale
} from '@/i18n'
Vue.prototype.$toast = Vue.$toast;

Vue.use(VueI18n)
Vue.use(VTooltip)
Vue.use(VueCookies)
Vue.use(Vuelidate)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(Toast, {
  // You can set your default options here
  position: POSITION.TOP_RIGHT,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false
});

Vue.prototype.$moment = moment
Vue.prototype.$_ = lodash

const messages = Object.assign(languages)

const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  messages // set locale messages
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')