import { ApiService } from '@/utils'
import { apiConstant } from '@/constants'

const client = new ApiService({})

export const getFaqListService = async (limit) => {
  try {
    return await client.get(apiConstant.FAQ_LIST, {
      limit
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const getFaqByIdService = async (id) => {
  try {
    return await client.get(apiConstant.FAQ_BY_ID + `/${id}`, {
      id
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const updateStatusFaqService = async (id, status) => {
  try {
    return await client.post(apiConstant.FAQ_UPDATE_STATUS, {
      id, status
    })
  } catch (error) {
    throw new Error(error)
  }
}
export const addFaqService = async (question, answer, status, tag) => {
  try {
    return await client.post(apiConstant.FAQ_ADD, {
      question, answer, status, tag
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const editFaqService = async (id, question, answer, status, tag) => {
  try {
    return await client.post(apiConstant.FAQ_EDIT, {
      id, question, answer, status, tag
    })
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteFaqService = async (id) => {
  try {
    return await client.delete(apiConstant.FAQ_DELETE + `/${id}`)
  } catch (error) {
    throw new Error(error)
  }
}
