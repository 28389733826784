import {
  SET_LOGGING_LIST,
  SET_LOGGING_DETAIL,
  SET_LOGGING_ERROR,
  SET_LOGGING_LOADING
} from '@/constants'

import {
  getLoggingByIdService,
  getLoggingListService
} from '@/services'

import { isSuccess } from '@/utils/statusCheck'

const loggingModule = {
  state: {
    loggingList: [],
    loggingDetail: null,
    isLoading: false,
    error: null
  },

  mutations: {
    [SET_LOGGING_LIST]: (state, payload) => {
      state.loggingList = payload
    },
    [SET_LOGGING_DETAIL]: (state, payload) => {
      state.loggingDetail = payload
    },
    [SET_LOGGING_ERROR]: (state, payload) => {
      state.error = payload
    },
    [SET_LOGGING_LOADING]: (state, payload) => {
      state.isLoading = payload
    }
  },

  actions: {
    getLoggingList: async ({ commit }, params = {}) => {
      try {
        commit(SET_LOGGING_LOADING, true)
        const resp = await getLoggingListService(params)
        if (isSuccess(resp.status)) {
          commit(SET_LOGGING_LIST, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting logging list:', error)
        commit(SET_LOGGING_ERROR, error.message)
        return false
      } finally {
        commit(SET_LOGGING_LOADING, false)
      }
    },

    getLoggingById: async ({ commit }, id) => {
      try {
        commit(SET_LOGGING_LOADING, true)
        const resp = await getLoggingByIdService(id)
        if (isSuccess(resp.status)) {
          commit(SET_LOGGING_DETAIL, resp.data)
          return true
        }
        return false
      } catch (error) {
        console.error('Error getting logging detail:', error)
        commit(SET_LOGGING_ERROR, error.message)
        return false
      } finally {
        commit(SET_LOGGING_LOADING, false)
      }
    }
  }
}

export { loggingModule }